import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, tap, switchMap } from 'rxjs/operators';
import { ICategory } from '../interface/category.interface';
import { ISubCategory } from '../interface/sub_category.interface';
import { environment } from './../../environments/environment';
import { IService } from '../interface/services.interface';
const base_url = environment.baseUrl;

@Injectable({
  providedIn: 'root',
})
export class HospitalServiceService {
  constructor(private http: HttpClient) {}

  services: any = [];
  categories: any = [];
  sub_categories: any = [];
  serviceState = new BehaviorSubject<any>(this.services);
  categoriesState = new BehaviorSubject<any>(this.categories);
  sub_categoriesState = new BehaviorSubject<any>(this.sub_categories);

  getServices(): Observable<any> {
    return this.http.get(`${base_url}services`);
  }
  getService(id: string): Observable<any> {
    return this.http.get(`${base_url}services/${id}`);
  }
  getCategories(): Observable<any> {
    this.http.get(`${base_url}category`).subscribe((data) => {
      this.categoriesState.next(data);
    });
    return this.categoriesState;
  }
  createCategory(data: ICategory): Observable<any> {
    this.http.post(`${base_url}category`, data).subscribe((data) => {
      this.getCategories();
    });
    return this.categoriesState;
  }
  getSubCategories(id: number): Observable<any> {
    this.http
      .get(`${base_url}sub_category/?category=${id}`)
      .subscribe((data) => {
        this.sub_categoriesState.next(data);
      });
    return this.sub_categoriesState;
  }
  getDepartments(): Observable<any> {
    return this.http.get(`${base_url}department`);
  }
  createSubCategory(data: ISubCategory): Observable<any> {
    this.http.post(`${base_url}sub_category`, data).subscribe((datas) => {
      this.getSubCategories(data.category);
    });
    return this.sub_categoriesState;
  }
  getServicesBySubCategories(id: number): Observable<any> {
    this.http
      .get(`${base_url}services/?sub_category=${id}`)
      .subscribe((data) => {
        this.serviceState.next(data);
      });
    return this.serviceState;
  }
  getSubCategory(id: number): Observable<any> {
    return this.http.get(`${base_url}sub_category/${id}`);
  }
  createService(data: IService): Observable<any> {
    this.http.post(`${base_url}services`, data).subscribe((datas) => {
      this.getServicesBySubCategories(data.sub_category);
    });
    return this.serviceState;
  }

  updateService(data: IService):Observable<any> {
    return this.http.put(`${base_url}services/${data.id}`, data)
  }

  generateService(data: any) {
    return (data.replace(/ /g, '_')).toLowerCase();
  }

  generate_keys(...args: any[]){
   let  text = 'key'
    args.forEach(element => {
      text += " " + element.trim()
    });
    return (text.replace(/ /g, '_')).toLowerCase();
  }
}
