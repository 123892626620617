/**
 * services-service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ServiceAccessType = 'only_staff' | 'only_patients' | 'all';

export const ServiceAccessType = {
    OnlyStaff: 'only_staff' as ServiceAccessType,
    OnlyPatients: 'only_patients' as ServiceAccessType,
    All: 'all' as ServiceAccessType
};

