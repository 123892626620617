<mat-progress-bar mode="indeterminate" *ngIf="loader">
</mat-progress-bar>
<div class="row m-3">
    <div class="col-md">
        <!-- left -->

    </div>
    <div class=" col-md-auto">
        <div class="example-button-container">
            <button mat-raised-button color="primary" (click)="createService()">Add Service</button>
        </div>
    </div>
</div>
<div class="card">
    <div class="card-body">
        <h1>
            {{sub_category_details?.data.sub_category_name | uppercase}}
        </h1>

    </div>
</div>


<div class="mt-5">
    <!-- <div class="row">
        <div class="col-md-4 mb-4" *ngFor="let item of services">
            <div class="card pointer border-primary">
                <div class="card-body border-primary">
                    <h1 class="card-title ">{{item?.service_name}}</h1>
                    <div class="row">
                        <div class="col-md">
                        </div>
                        <div class="col-md-auto">
                            <button (click)="deleteService(item.id)" class="m-1" mat-stroked-button>Delete</button>
                            <button (click)="editFields(item)" class="m-1" mat-stroked-button color="primary">Edit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</div>





<table mat-table [dataSource]="services" class="">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- Position Column -->
    <!-- <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef> No. </th>
        <td mat-cell *matCellDef="let element"> </td>
    </ng-container> -->

    <!-- Name Column -->
    <ng-container matColumnDef="service_name">
        <th mat-header-cell *matHeaderCellDef> Service Name </th>
        <td mat-cell *matCellDef="let element"> {{element.service_name}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="service_key">
        <th mat-header-cell *matHeaderCellDef> Service Key </th>
        <td mat-cell *matCellDef="let element"> {{element.service_key}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="addDocuments(element)">
                    <span>Add Document</span>
                  </button>
                <button mat-menu-item (click)="editFields(element)">
                  <span>Open</span>
                </button>
                <button mat-menu-item (click)="deleteService(element.id)">
                  <span>Delete</span>
                </button>
            </mat-menu>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator [length]="dataCount" [pageSize]="10" [pageSizeOptions]="[10, 15, 25]"></mat-paginator>