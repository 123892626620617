<mat-dialog-content>
  <div class="row">
    <div class="col-md">
      <div class="row">
        <div class="col-md">
          <h2>New Service</h2>
        </div>
        <div class="col-md">
          <div class="row">
            <div class="col">
              <mat-form-field>
                <mat-label>Select Sub Category</mat-label>
                <mat-select>
                  <mat-option *ngFor="let sc of sub_categories" (click)="changeSubCategory(sc)"
                    [value]="sc.id">{{sc.sub_category_name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

          </div>


        </div>

        <hr>
      </div>
      <div>
        <div class="row">
          <div class="col-md-3">
            <mat-form-field appearance="outline" style="width: 100%">
              <input [(ngModel)]="newData.service_name" matInput placeholder="service name" />
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field appearance="outline" style="width: 100%">
              <input [(ngModel)]="newData.service_cost" matInput type="number" class="example-right-align" value="0"
                placeholder="0" />
              <span matPrefix>NGN&nbsp;</span>
              <mat-hint style="color: red" align="end" *ngIf="!newData.service_cost">No cost attached</mat-hint>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-checkbox class="example-margin" [(ngModel)]="showhmodetails">HMO COSTS</mat-checkbox>
            <div *ngIf="showhmodetails">
              <mat-form-field appearance="fill">
                <mat-label>HMO and Costs</mat-label>
                <mat-select>
                  <mat-option *ngFor="let item of specialCost.hmo_costs; let i = index" (click)="editHMOCost(item)">
                    <mat-icon (click)="deleteHMOCost(i)">cancel</mat-icon>
                    {{ item.hmo_name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div class="row">
                <mat-form-field appearance="outline" class="col">
                  <mat-label>HMO NAME</mat-label>
                  <input [(ngModel)]="hmo_details.hmo_name" matInput placeholder="Placeholder" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="col">
                  <mat-label>HMO KEY</mat-label>
                  <input [(ngModel)]="hmo_details.hmo_key" matInput placeholder="Placeholder" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="col">
                  <mat-label>Outline form field</mat-label>
                  <input [(ngModel)]="hmo_details.cost" matInput placeholder="Placeholder" />
                </mat-form-field>
                <div class="col">
                  <button type="button" (click)="addHMOCost()" class="btn btn btn-primary btn-sm">
                    Add Cost
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <!-- <h3>PACKAGES COSTS</h3> -->
            <mat-checkbox class="example-margin" [(ngModel)]="showpackagescost">PACKAGES COSTS</mat-checkbox>
            <div *ngIf="showpackagescost">
              <mat-form-field appearance="fill">
                <mat-label>Packages And Costs</mat-label>
                <mat-select>
                  <mat-option *ngFor="
                      let item of specialCost.packages_cost;
                      let i = index
                    " (click)="editPackagesCost(item)">
                    <mat-icon (click)="deletePackagesCost(i)">cancel</mat-icon>
                    {{ item.package_name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <div class="row">
                <mat-form-field appearance="outline" class="col">
                  <mat-label>PACKAGE NAME</mat-label>
                  <input [(ngModel)]="packages_details.package_name" matInput placeholder="Placeholder" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="col">
                  <mat-label>PACKAGE KEY</mat-label>
                  <input [(ngModel)]="packages_details.package_key" matInput placeholder="Placeholder" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="col">
                  <mat-label>COST</mat-label>
                  <input [(ngModel)]="packages_details.cost" matInput placeholder="Placeholder" />
                </mat-form-field>
                <div class="col">
                  <button (click)="addPackagesCost()" type="button" class="btn btn btn-primary btn-sm">
                    Add Cost
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>Min Age range</mat-label>
              <input [(ngModel)]="age_range.min_age" type="number" matInput placeholder="service name" />
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>Max Age range</mat-label>
              <input [(ngModel)]="age_range.max_age" type="number" matInput placeholder="service name" />
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>Service Type</mat-label>
              <mat-select [(ngModel)]="selected_service_type">
                <mat-option *ngFor="let service of service_type | keyvalue" [value]="service.value">
                  {{ service.key }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>Sex</mat-label>
              <mat-select [(ngModel)]="selected_service_sex">
                <mat-option *ngFor="let sex of service_sex" [value]="sex">
                  {{ sex }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>Access Type</mat-label>
              <mat-select [(ngModel)]="selected_access_type">
                <mat-option *ngFor="let access of service_access_type" [value]="access.value">
                  {{ access.title }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>



        <div class="row">
          <div class="col-md-6">
            <div>
              <h2>Fields</h2>
            </div>
            <div class="row example-container">
              <div class="col-md-6">
                <div class="input-group mb-3">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <input [(ngModel)]="field.title" matInput placeholder="Enter Field Title" />
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-6">
                <mat-form-field appearance="outline" class="example-full-width">
                  <mat-label>Select Input type</mat-label>
                  <mat-select [(value)]="field.fieldType" name="field.fieldType">
                    <mat-option *ngFor="let fields of fieldTypes" [value]="fields.value">
                      {{ fields.viewValue }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="outline" style="width: 100%">
                  <mat-label>Select Department</mat-label>
                  <mat-select [(value)]="department_route" name="department_route">
                    <mat-option value="none">Default</mat-option>
                    <mat-option *ngFor="let item of departments; let i = index" [value]="item.department_route">
                      {{ item.department_name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="outline" style="width: 100%">
                  <mat-label>Select Special Group</mat-label>
                  <mat-select name="department_route">
                    <mat-option value="none">Default</mat-option>
                    <mat-option *ngFor="let item of special_groups; let i = index" [value]="item.viewValue"
                      (click)="add_groupedData(item.value)">
                      {{ item.value }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <div class="input-group mb-3">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <input [(ngModel)]="field.fieldsHeadingTitle" matInput placeholder="Enter Field Heading Title" />
                  </mat-form-field>
                </div>
              </div>
            </div>
            <mat-form-field class="example-chip-list" appearance="outline" class="example-full-width"
              *ngIf="field.fieldType == 'range'">
              <mat-label>Range Of Values</mat-label>
              <mat-chip-grid #chipGrid aria-label="Fruit selection">
                <mat-chip-row *ngFor="let range of field.range" (removed)="removeFieldRange(range)">
                  {{ range }}
                  <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-row>
                <input placeholder="New Field Range..." [matChipInputFor]="chipGrid"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="addFieldRange($event)" />
              </mat-chip-grid>
            </mat-form-field>
          </div>

          <div class="col-md-6">
            <h2 class="text-info">Options</h2>
            <div class="card border-info p-2 mb-3">
              <div class="row">
                <div class="col">
                  <mat-checkbox [(ngModel)]="selectedExtensionState" class="example-margin">Extended ?</mat-checkbox>
                </div>
              </div>

              <div class="row">
                <div class="col-md m-1">
                  <button *ngFor="let item of global_sub_fields" (click)="editSubField(item)" class="col-md-1"
                    type="button" class="btn btn-info" style="margin: 2px">
                    {{ item.title }}
                    <span class="badge badge-light" (click)="deleteSubFields(item)">X</span>
                  </button>
                </div>
              </div>
              <div class="row example-container">
                <div class="col-md-6">
                  <div class="input-group mb-3">
                    <mat-form-field appearance="outline" class="example-full-width">
                      <mat-label>Name</mat-label>
                      <input [(ngModel)]="sub_fields.title" matInput placeholder="Placeholder" />
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-md-6">
                  <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Type of Options</mat-label>
                    <mat-select name="field.fieldType" value="radio" [(value)]="selectedExtension">
                      <mat-option [value]="optType.value" *ngFor="let optType of optionsType">
                        {{ optType.viewValue }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div *ngIf="selectedExtensionState">
                  <h3 class="text-warning">Extended Options</h3>
                  <div class="row">
                    <div class="col-md m-1">
                      <button *ngFor="let item of global_extension" (click)="editSubField(item)" class="col-md-1"
                        type="button" class="btn btn-warning" style="margin: 2px">
                        {{ item.title }}
                        <span class="badge badge-light" (click)="deleteExtension(item)">X</span>
                      </button>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-md-6">
                      <div class="input-group mb-3">
                        <mat-form-field appearance="outline" class="example-full-width">
                          <mat-label>Name of extension</mat-label>
                          <input [(ngModel)]="extension.title" matInput />
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <mat-form-field appearance="outline" class="example-full-width">
                        <mat-label>Type of extension</mat-label>
                        <mat-select name="field.fieldType" value="radio" [(value)]="selectedExtension">
                          <mat-option [value]="ext.value" *ngFor="let ext of extensions">
                            {{ ext.viewValue }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-md-12" *ngIf="selectedExtension == 'range'">
                      <div class="input-group mb-3">
                        <mat-form-field class="example-chip-list" appearance="outline" class="example-full-width">
                          <mat-label>Add Range</mat-label>
                          <mat-chip-grid #chipGrid aria-label="Fruit selection">
                            <mat-chip *ngFor="let range of extension_range" (removed)="remove(range)">
                              {{ range }}
                              <button matChipRemove>
                                <mat-icon>cancel</mat-icon>
                              </button>
                            </mat-chip>
                            <input placeholder="New range..." [matChipInputFor]="chipGrid"
                              [matChipInputSeparatorKeyCodes]="
                                separatorKeysCodes
                              " [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)" />
                          </mat-chip-grid>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="col-auto">
                      <button type="button" class="btn btn-warning" (click)="addExtensions()">
                        Add more extension
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <!-- <button mat-button>Add option</button> -->
                </div>
                <div class="col-auto">
                  <button type="button" class="btn btn-info" (click)="addSubFields()">
                    Add option
                  </button>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="row" *ngIf="newData.category_key == 'radiology'">
            <div class="col-4">
              <input [(ngModel)]="singlefindings.title" type="text" name="" id="" placeholder="title"
                class="form-control form-control-sm" />
              <br />
              <input [(ngModel)]="singlefindings.key" type="text" name="" id="" placeholder="key"
                class="form-control form-control-sm" />
              <br />
              <input [(ngModel)]="singlefindings.value" type="text" placeholder="value"
                class="form-control form-control-sm" />
              <div class="m-1">
                <button class="btn btn-secondary btn-sm mt-2" (click)="addFindings()">
                  Add Findings
                </button>
              </div>
            </div>

            <div class="col">

              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Title</th>
                    <th scope="col">Key</th>
                    <th scope="col">Value</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of findings; index as i">
                    <td>{{ item.title }}</td>
                    <td>{{ item.key }}</td>
                    <td>{{ item.value }}</td>
                    <td>
                      <mat-icon (click)="removeFindings(i)" style="color: red">delete</mat-icon>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br />
            <hr />
          </div>

          <!-- holds ranges -->
          <div *ngIf="field.fieldType !== 'radio'" class="row">
            <div class="col-md-6">
              <h3>Range - Female</h3>
              <div class="row">
                <div class="col-md">
                  <div class="input-group mb-3">
                    <input [(ngModel)]="field?.range_female.lower" type="text" class="form-control"
                      placeholder="Lower range" />
                  </div>
                </div>
                <div class="col-md">
                  <div class="input-group mb-3">
                    <input [(ngModel)]="field?.range_female.upper" type="text" class="form-control"
                      placeholder="Upper range" />
                  </div>
                </div>
                <div class="col-md">
                  <div class="input-group mb-3">
                    <input [(ngModel)]="field?.range_female.general" type="text" class="form-control"
                      placeholder="General range" />
                  </div>
                </div>

                <div class="col-md">
                  <div class="input-group mb-3">
                    <input [(ngModel)]="field?.range_female.unit" class="form-control" placeholder="Unit" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <h3>Range - Male</h3>
              <div class="row">
                <div class="col-md">
                  <div class="input-group mb-3">
                    <input [(ngModel)]="field?.range_male.lower" class="form-control" placeholder="Lower range" />
                  </div>
                </div>
                <div class="col-md">
                  <div class="input-group mb-3">
                    <input [(ngModel)]="field?.range_male.upper" type="number" class="form-control"
                      placeholder="Upper range" />
                  </div>
                </div>
                <div class="col-md">
                  <div class="input-group mb-3">
                    <input [(ngModel)]="field?.range_male.general" type="text" class="form-control"
                      placeholder="General range" />
                  </div>
                </div>
                <div class="col-md">
                  <div class="input-group mb-3">
                    <input [(ngModel)]="field?.range_male.unit" type="text" class="form-control" placeholder="Unit" />
                  </div>
                </div>

              </div>
            </div>

            <div class="col-md-6">
              <h3>Range - Children</h3>
              <div class="row">
                <div class="col-md">
                  <div class="input-group mb-3">
                    <input [(ngModel)]="field?.range_children.lower" type="number" class="form-control"
                      placeholder="Lower range" />
                  </div>
                </div>
                <div class="col-md">
                  <div class="input-group mb-3">
                    <input [(ngModel)]="field?.range_children.upper" type="number" class="form-control"
                      placeholder="Upper range" />
                  </div>
                </div>
                <div class="col-md">
                  <div class="input-group mb-3">
                    <input [(ngModel)]="field?.range_children.general" type="text" class="form-control"
                      placeholder="General range" />
                  </div>
                </div>

                <div class="col-md">
                  <div class="input-group mb-3">
                    <input [(ngModel)]="field.range_children.unit" type="text" class="form-control"
                      placeholder="Unit" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md"></div>
            <div class="col-md-auto">
              <button (click)="addFields()" mat-stroked-button color="warn">
                Add Field <mat-icon>arrow_forward</mat-icon>
              </button>
            </div>
          </div>
        </div>

        <hr />

        <div>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Title</th>
                <th scope="col">Key</th>
                <th scope="col">Upper Range - Female</th>
                <th scope="col">Lower Range - Female</th>
                <th scope="col">Upper Range - Male</th>
                <th scope="col">Lower Range - Male</th>
                <th scope="col">Field Type</th>
                <th scope="col">action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of newData.fields">
                <th scope="row">{{ item.title }}</th>
                <td>{{ item?.key }}</td>
                <td>{{ item?.range_female?.upper }}</td>
                <td>{{ item?.range_female?.lower }}</td>
                <td>{{ item?.range_male?.upper }}</td>
                <td>{{ item?.range_male?.lower }}</td>
                <td>{{ item?.fieldType }}</td>

                <td>
                  <mat-icon (click)="removeField(item)" style="color: red; cursor: pointer"
                    aria-hidden="false">delete</mat-icon>
                </td>
                <td>
                  <mat-icon (click)="editField(item)" style="color: skyblue; cursor: pointer"
                    aria-hidden="false">edit</mat-icon>
                </td>
              </tr>
            </tbody>
          </table>


        </div>
      </div>
    </div>
    <!-- prset table -->
    <div>
      <textarea [(ngModel)]="newData.description" placeholder="Description" style="width: 100%;" name=""
        id=""></textarea>
    </div>

  </div>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial (click)="createService()">
    Create
  </button>
</mat-dialog-actions>