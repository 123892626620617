import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HospitalServiceService } from './../../services/hospital-service.service';
import { ISubCategory } from '../../interface/sub_category.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SubCategoryService } from 'src/app/core/api/v1/api/subCategory.service';
import { DepartmentsService } from 'src/app/core/api/v1/api/departments.service';
import { CategoryService } from 'src/app/core/api/v1/api/category.service';
import { SubCategory } from 'src/app/core/api/v1/model/subCategory';

@Component({
  selector: 'app-create-sub-categories',
  templateUrl: './create-sub-categories.component.html',
  styleUrls: ['./create-sub-categories.component.css'],
})
export class CreateSubCategoriesComponent implements OnInit {
  constructor(
    private hservice: HospitalServiceService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private sub_category_service: SubCategoryService,
    private department_service: DepartmentsService,
    private category_service: CategoryService
  ) {}
  newSubCat: SubCategory = {
    sub_category_name: '',
    category: 0,
    department: 0,
  };

  departments: any = [];
  categories: any = [];

  ngOnInit(): void {
    this.getDepartments();
    this.getCategories();
  }

  createSubCategory() {
    this.newSubCat.sub_category_key = this.hservice.generate_keys(
      this.newSubCat.sub_category_name
    );
    console.log(this.newSubCat, 'sub cat');
    this.sub_category_service
      .createSubCategory(this.newSubCat)
      .subscribe((data: any) => {
        console.log(data);
        if (data.error) {
          this._snackBar.open(data.message, 'close');
        } else {
          this._snackBar.open(data.message, 'close');
        }
      });
    this.closeDialog();
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  getDepartments() {
    this.department_service.getAllDepartment().subscribe((data: any) => {
      this.departments = data.data;
    });
  }
  getCategories() {
    this.category_service.getAllCategories().subscribe((data: any) => {
      this.categories = data.data;
    });
  }
}
