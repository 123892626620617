import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-service',
  templateUrl: './create-service.component.html',
  styleUrls: ['./create-service.component.css']
})
export class CreateServiceComponent implements OnInit {

  constructor() { }

  subtest_parameters:boolean = false
  test: any = {
    test_name: '',
    parameters: {
      name: '',
      data_type: '',
      values_num: [],
      values_text: [],
      key: '',
    },
  };

  savedTest: any = [];

  investigation: any = {
    title: '',
    tests: [],
  };

  currentRange: any;

  addParameters() {
    this.savedTest.push(this.test.parameters);
    this.test.parameters = {
      name: '',
      data_type: '',
      values_num: [],
      values_text: [],
      key: '',
    };
    console.log(this.savedTest, 'tests');
  }

  addRangeNum() {
    this.test.parameters.values_num.push(this.currentRange);
    this.currentRange = '';
  }


  ngOnInit(): void {
  }

}
