<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
        [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false">
        <mat-nav-list class="m-3">
            <h2>Hospital Services</h2>
            <hr>
            <div class="side-bar-item" fxLayout="column">
                <a routerLink='/all-services' routerLinkActive="activeLink" [routerLinkActiveOptions]={exact:true}>
                    <!-- <mat-icon>book</mat-icon> -->
                    <span>ALL SERVICES</span>
                </a>
            </div>
            <div class="side-bar-item" fxLayout="column">
                <a routerLink='/' routerLinkActive="activeLink" [routerLinkActiveOptions]={exact:true}>
                    <!-- <mat-icon>book</mat-icon> -->
                    <span>CATEGORIES</span>
                </a>
            </div>
            <div class="side-bar-item " fxLayout="column">
                <a routerLink='/tests' routerLinkActive="activeLink">
                    <!-- <mat-icon>book</mat-icon> -->
                    <span>TEST PACKAGES</span>
                </a>
            </div>
            <div class="side-bar-item " fxLayout="column">
                <a routerLink='/depts'>
                    <!-- <mat-icon>book</mat-icon> -->
                    <span>Departments</span>
                </a>
            </div>
        </mat-nav-list>

    </mat-sidenav>
    <mat-sidenav-content>
        <mat-toolbar color="primary">
            <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
                <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>

            <span class="col-md">Hospital Services</span>
            <span class="example-spacer m-3">{{user?.given_name}} {{user?.family_name}}</span>
            <mat-icon matTooltip="Log out" class="pointer" (click)="logout()">logout</mat-icon>
        </mat-toolbar>
        <div class="m-4">
            <mat-icon matTooltip="go back" class="pointer" (click)="back()">arrow_back</mat-icon>
            <hr>
            <!-- <button (click)="back()">back</button> -->
            <router-outlet>
            </router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>