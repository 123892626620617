import { APP_INITIALIZER, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListCategoriesComponent } from './pages/list-categories/list-categories.component';
import { ListSubCategoriesComponent } from './pages/list-sub-categories/list-sub-categories.component';
import { ViewSubCategoryComponent } from './pages/view-sub-category/view-sub-category.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { KeycloakService } from 'keycloak-angular';
import { initializer } from 'AppInit';
import { AuthService } from './service/auth.service';
import { AuthGuard } from './auth.guard';
import { CreateTestsComponent } from './pages/create-tests/create-tests.component';
import { ListTestsComponent } from './pages/list-tests/list-tests.component';
import { ListServicesComponent } from './pages/list-services/list-services.component';
import { CreateDepartmentComponent } from './pages/dept/create-department/create-department.component';
const routes: Routes = [
  {
    path: '',
    component: ListCategoriesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'sub-categories/:category',
    component: ListSubCategoriesComponent,
  },
  {
    path: 'view-subcategory',
    component: ViewSubCategoryComponent,
  },

  {
    path: 'tests',
    component: ListTestsComponent,
  },
  {
    path: 'depts',
    component: CreateDepartmentComponent,
  },
  {
    path: 'all-services',
    component: ListServicesComponent,
  },

  //   path: 'create-services',
  //   component: ViewSubCategoryComponent,
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
