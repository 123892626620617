<mat-progress-bar mode="indeterminate" *ngIf="loader">
</mat-progress-bar>


<h2>
    Categories</h2>

<div class="row">
    <div class="col-md">
        <button (click)="createCategory()" color="primary" mat-mini-fab>
            <mat-icon  >add</mat-icon>
          </button>
    </div>

</div>


<div class="row mt-3">
    <div class="col-md-3 mt-2  " *ngFor="let item of categories">
        <div class="card pointer border-primary" [routerLink]="['sub-categories/', item.id]">
            <h2 class="card-body text-primary">
                {{item.category_name}}
            </h2>

        </div>
    </div>
</div>