import { Component, Injectable, OnInit } from '@angular/core';
import { ActivatedRoute, ActivationStart, Router } from '@angular/router';
import { HospitalServiceService } from './../../services/hospital-service.service';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CreateSubCategoriesComponent } from './../create-sub-categories/create-sub-categories.component';
import { SubCategoryService } from 'src/app/core/api/v1/api/subCategory.service';
import { SubCategory } from 'src/app/core/api/v1/model/subCategory';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'app-list-sub-categories',
  templateUrl: './list-sub-categories.component.html',
  styleUrls: ['./list-sub-categories.component.css'],
})
export class ListSubCategoriesComponent implements OnInit {
  displayedColumns: string[] = ['sub_category', 'category', 'action'];
  dataSource: any = [];
  category: number = 0;
  loader: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private hservice: HospitalServiceService,
    private dialog: MatDialog,
    private sub_category_service: SubCategoryService
  ) {
    this.activatedRoute.params.subscribe((data) => {
      this.category = data['category'];
      console.log(this.category);
    });
  }

  ngOnInit(): void {
    this.fetchSubCategories();
  }

  fetchSubCategories() {
    this.loader = true;
    this.sub_category_service
      .getAllSubCategories(this.category)
      .subscribe((data: any) => {
        console.log(data);
        this.dataSource = data.data;
        console.log(this.dataSource);
        this.loader = false;
      });
  }

  viewSubCategory(element: SubCategory | any) {
    this.router.navigateByUrl(
      `/view-subcategory?sub_category=${element.id}&department=${element.department.department_route}&category=${element.category.category_key}&category_id=${element.category.id}`
    );
  }

  deleteSubCategory(id: any) {
    if (!confirm('are you sure you want to delete this sub category?')) {
      return;
    }
    this.loader = true;
    this.sub_category_service.removeSubCategory(id).subscribe((data: any) => {
      this.loader = false;
      this.fetchSubCategories();
    });
  }
  createSUbCategory() {
    this.dialog.open(CreateSubCategoriesComponent, {
      width: '500px',
    });
  }
}
