<mat-progress-bar mode="indeterminate" *ngIf="loader">
</mat-progress-bar>
<div class="row">
    <div class="col-md">
        <!-- left -->
    </div>
    <div class="col-md-auto">
        <div class="example-button-container">
            <button (click)="createSUbCategory()" mat-mini-fab color="primary">  <mat-icon >add</mat-icon></button>
        </div>
    </div>
</div>

<div class="mt-3">
    <table mat-table [dataSource]="dataSource">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="sub_category">
            <th mat-header-cell *matHeaderCellDef> Sub Category Name </th>
            <td mat-cell *matCellDef="let element"> {{element?.sub_category_name | uppercase}} </td>
        </ng-container>

        <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef> Category Name </th>
            <td mat-cell *matCellDef="let element"> {{element?.category?.category_name | uppercase}} </td>
        </ng-container>

        <!-- <ng-container matColumnDef="department">
            <th mat-header-cell *matHeaderCellDef> Department Name </th>
            <td mat-cell *matCellDef="let element"> {{element?.department?.department_name | uppercase}} </td>
        </ng-container> -->
        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon style="cursor: pointer; color: rgba(32, 164, 243);
                    ;">more_vert</mat-icon>
                  </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="viewSubCategory(element)">
                      <mat-icon  color="primary">info</mat-icon>
                      <span>View Sub Category</span>
                    </button>
                    <button mat-menu-item (click)="deleteSubCategory(element.id)">
                      <mat-icon  color="warn">delete</mat-icon>
                      <span>Delete Sub Category</span>
                    </button>
                    <button mat-menu-item>
                        <mat-icon color="accent">edit</mat-icon>
                        <span>Edit Sub Category</span>
                      </button>
                </mat-menu>
                <!-- <mat-icon (click)="viewSubCategory(element)" aria-hidden="false" aria-label="Example home icon">more_vert</mat-icon> -->
                <!-- <mat-menu #menu="matMenu">
                    <button mat-menu-item>
                      <mat-icon>dialpad</mat-icon>
                      <span>Redial</span>
                    </button>
                    <button mat-menu-item disabled>
                      <mat-icon>voicemail</mat-icon>
                      <span>Check voice mail</span>
                    </button>
                    <button mat-menu-item>
                      <mat-icon>notifications_off</mat-icon>
                      <span>Disable alerts</span>
                    </button>
                </mat-menu> -->
            </td>
        </ng-container>

        <!-- Name Column -->


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>