import { Component, OnInit } from '@angular/core';
import { HospitalServiceService } from './../../services/hospital-service.service';
import { ICategory } from '../../interface/category.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { CategoryService } from 'src/app/core/api/v1/api/category.service';
import { Category } from 'src/app/core/api/v1/model/category';

@Component({
  selector: 'app-create-categories',
  templateUrl: './create-categories.component.html',
  styleUrls: ['./create-categories.component.css'],
})
export class CreateCategoriesComponent implements OnInit {
  constructor(
    private hservice: HospitalServiceService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private category_service: CategoryService
  ) {}

  category = [];

  newCat: Category = {
    category_name: '',
    category_key: '',
  };

  ngOnInit(): void {}

  createNewCategory() {
    this.newCat.category_key = this.hservice.generate_keys(
      this.newCat.category_name
    );
    console.log(this.newCat, 'new cat');
    this.category_service.createCategory(this.newCat).subscribe((data: any) => {
      console.log(data);
      if (data.error) {
        this._snackBar.open(data.message, 'close');
      } else {
        this._snackBar.open(data.message, 'close');
      }
    });
    this.category_service.getAllCategories();
    this.closeDialog();
  }

  closeDialog() {
    this.dialog.closeAll();
  }
}
