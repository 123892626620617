import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HospitalServices } from '../../core/api/v1';

@Component({
  selector: 'app-service-documents',
  templateUrl: './service-documents.component.html',
  styleUrls: ['./service-documents.component.css'],
})
export class ServiceDocumentsComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      service: HospitalServices;
    }
  ) {
    console.log(data)
  }
}
