<div class="side-bar-main" fxLayout="row wrap">
    <div fxLayout="column wrap" fxLayoutAlign="start" class="title">
        <div class="side-bar-title" fxLayout="column">
            <span>Hospital Services</span>
        </div>
        <div fxLayout="column" fxLayoutAlign="space-between">
            <div class="side-bar-item" routerLinkActive="activeLink" fxLayout="column">
                <a routerLink='/'>
                    <mat-icon>more</mat-icon>
                    <span>All Services</span>
                </a>
            </div>
            <div class="side-bar-item" routerLinkActive="activeLink" fxLayout="column">
                <a routerLink='/'>
                    <mat-icon>person_outline</mat-icon>
                    <span>Categories</span>
                </a>
            </div>
            <div class="side-bar-item" routerLinkActive="activeLink" fxLayout="column">
                <a routerLink='/appointments'>
                    <mat-icon>today</mat-icon>
                    <span>Appointments</span>
                </a>
            </div>
            <!-- <div class="side-bar-item" routerLinkActive="activeLink" fxLayout="column">
                <a routerLink='/procedures'>
                    <mat-icon>monitor_heart</mat-icon>
                    <span>Procedures</span>
                </a>
            </div> -->
            <div class="side-bar-item" routerLinkActive="activeLink" fxLayout="column">
                <a routerLink='/settings'>
                    <mat-icon>settings</mat-icon>
                    <span>Settings</span>
                </a>
            </div>

        </div>
    </div>
</div>