import { Component, OnInit } from '@angular/core';
import { HospitalServiceService } from './../../services/hospital-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { CreateCategoriesComponent } from './../create-categories/create-categories.component';
import { CategoryService } from 'src/app/core/api/v1/api/category.service';

@Component({
  selector: 'app-list-categories',
  templateUrl: './list-categories.component.html',
  styleUrls: ['./list-categories.component.css'],
})
export class ListCategoriesComponent implements OnInit {
  constructor(
    private hservice: HospitalServiceService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private category_service: CategoryService
  ) {}

  loader: boolean = false;

  ngOnInit(): void {
    this.getCategories();
  }

  categories: any = [];

  getCategories() {
    this.loader = true;
    this.category_service.getAllCategories().subscribe((data: any) => {
      console.log(data, 'cats components');
      if (data.error) {
        this._snackBar.open('error', 'close');
      } else {
        this.categories = data.data;
        this.loader = false;
      }
    });
  }

  createCategory() {
    this.dialog.open(CreateCategoriesComponent, {
      // height: '400px',
      width: '400px',
    });
  }
}
