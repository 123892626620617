import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';

import { debounceTime, map, Observable, of, startWith, switchMap } from 'rxjs';
import { PackageEntity } from 'src/app/core/api/v1';
import { PackageServicesService } from 'src/app/core/api/v1/api/packageServices.service';
import { ServicesService } from 'src/app/core/api/v1/api/services.service';

// import { TestServicesService } from 'src/app/core/api/v1/api/testServices.service';
import { CreatePackageData } from 'src/app/core/api/v1/model/createPackageData';
import { HospitalServices } from 'src/app/core/api/v1/model/hospitalServices';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { NewservicespackagesService } from 'src/app/core/api/v1/api/newservicespackages.service';
export interface User {
  name: string;
}
@Component({
  selector: 'app-create-tests',
  templateUrl: './create-tests.component.html',
  styleUrls: ['./create-tests.component.css'],
})
export class CreateTestsComponent implements OnInit {
  myControl = new UntypedFormControl();
  options: HospitalServices[] | any = [{ service_name: '' }];
  filteredOptions: Observable<HospitalServices[]> | any = [];

  constructor(
    private service_service: ServicesService,
    private package_service: PackageServicesService,
    @Inject(MAT_DIALOG_DATA) public dialogRecievedService: any,
    public dialogRef: MatDialogRef<CreateTestsComponent>,
    private newService: NewservicespackagesService,
    private _snackBar: MatSnackBar
  ) {
    if (dialogRecievedService.package !== undefined) {
      this.selectedServices = this.dialogRecievedService?.package?.services;
      this.selected_optional_Services =
        this.dialogRecievedService?.package?.optional_services;
      this.package_Details = this.dialogRecievedService?.package;
      this.test_name = this.dialogRecievedService?.package.package_name;
      this.differential_key = this.dialogRecievedService?.package.package_key;
      this.package_is_ranked =
        this.dialogRecievedService?.package.package_is_ranked;
      this.cost = this.dialogRecievedService?.package?.cost;
    }
  }

  packageRankData = ['ranked', 'unranked'];
  selectedServices: HospitalServices[] = [];
  selected_optional_Services: HospitalServices[] = [];

  package_Details: any;
  test_name: string = '';
  cost?: number = 0;
  differential_key: string = '';
  package_is_ranked: CreatePackageData.PackageIsRankedEnum = 'unranked';
  listOfServicesInPackage: any[] = [];
  edit: boolean = false;
  mappedService?: any;
  serviceToPackageCost: number = 0;
  // serviceToPackageCost: number = 0;
  displayedColumns = ['service', 'cost', 'action'];
  selectedServiceToCostRecords: any;
  loading: boolean = false;
  accumulatedCostOfServicesInPackage: number = 0;
  testcost?: any;

  ngOnInit(): void {
    this.myControl.valueChanges
      .pipe(
        debounceTime(500),
        switchMap((val) => {
          return this.service_service.getServices(
            undefined,
            0,
            'ASC',
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            val
          );
        })
      )
      .subscribe((data: any) => {
        this.filteredOptions = data;
      });
    this.fetchServices();
  }

  selectedTabIndex: number = 0;

  tabClick(event: MatTabChangeEvent) {
    this.selectedTabIndex = event.index;
    console.log('Selected tab index:', this.selectedTabIndex);
  }

  fetchServices() {
    return this.service_service
      .getServices(100, 0, 'ASC', undefined)
      .subscribe((data) => {
        this.options = data;
      });
  }
  addService() {
    this.loading = true;
    if (this.edit == true) {
      this.selectedServices.push(this.myControl.value);
      this.processTestPackages();
      console.log('editss');
    } else {
      this.selectedServices.push(this.myControl.value);
      this.mappedService = this.myControl.value;
      this.processTestPackages();
      console.log(this.selectedServices, 'editss false');
    }
  }

  addOptionalService() {
    this.loading = true;
    if (this.edit == true) {
      this.selected_optional_Services.push(this.myControl.value);
      this.processTestPackages();
      console.log('editss');
    } else {
      this.selected_optional_Services.push(this.myControl.value);
      this.mappedService = this.myControl.value;
      this.processTestPackages();
      console.log(this.selected_optional_Services, 'editss false');
    }
  }
  removeOptionalService(index: number) {
    this.loading = true;
    this.selected_optional_Services.splice(index, 1);
    this.processTestPackages();
  }

  removeService(data: any, index: number) {
    this.loading = true;
    this.selectedServices.splice(index, 1);
    this.processTestPackages();
    // if (this.edit == true) {
    //   this.selectedServices.splice(index, 1);
    //   this.processTestPackages();
    // } else {
    //   this.selectedServices.splice(index, 1);
    //   this.processTestPackages();
    // }
  }

  deleteTests() {
    if (confirm('Are you sure you want to delete?') == true) {
      this.package_service
        .removePackages(this.dialogRecievedService.package.id)
        .subscribe({
          next: (data) => {
            this.loading = false;
            this._snackBar.open('deleted', 'close');
            this.dialogRef.close(data);
          },
          error: () => {
            this.loading = false;
            this._snackBar.open('error', 'close');
          },
        });
    } else {
    }
  }

  displayFn(service: HospitalServices): string {
    return service && service.service_name ? service.service_name : '';
  }

  processTestPackages() {
    let newData: CreatePackageData = {
      cost: this.cost || 0,
      package_name: this.test_name,
      package_is_ranked: this.package_is_ranked,
      package_key: this.differential_key,
      services: this.returnOnlyId(this.selectedServices),
      optional_services: this.returnOnlyId(this.selected_optional_Services),
    };
    this.edit = false;
    if (this.dialogRecievedService.package !== undefined) {
      this.upDatePackage();
    } else {
      this.createPackage(newData);
    }
  }

  upDatePackage() {
    let newData: CreatePackageData = {
      cost: this.cost || 0,
      package_name: this.test_name,
      package_is_ranked: this.package_is_ranked,
      package_key: this.differential_key,
      services: this.returnOnlyId(this.selectedServices),
      optional_services: this.returnOnlyId(this.selected_optional_Services),
    };
    newData.id = this.dialogRecievedService.package.id;
    console.log(newData);

    this.package_service.updatePackages(newData).subscribe((data: any) => {
      this.loading = false;
      this._snackBar.open('Update successful', 'close', {
        duration: 3000,
      });
      this.fetchServiceCostInPackage();
    });
  }

  createPackage(newData: CreatePackageData) {
    this.package_service.createPackage(newData).subscribe({
      next: () => {
        this.loading = false;
        this._snackBar.open('Successful', 'close', {
          duration: 3000,
        });
        this.processServiceCostInPackage();
      },
      error: () => {
        this._snackBar.open('Error', 'close', {
          duration: 3000,
        });
      },
    });
  }

  returnOnlyId(array: any) {
    let ids: any[] = [];
    array.forEach((element: any) => {
      ids.push(element.id);
    });
    return ids;
  }

  private _filter(name: string): HospitalServices[] {
    const filterValue = name.toLowerCase();

    return this.options.filter((option: any) =>
      option.service_name.toLowerCase().includes(filterValue)
    );
  }

  processServiceCostInPackage() {
    if (this.edit == false) {
      this.addServiceExtraInfoInPackage();
      this.fetchServiceCostInPackage();
    } else {
      this.editServiceCostInPackage(this.selectedServiceToCostRecords);
      this.fetchServiceCostInPackage();
    }
  }

  addServiceExtraInfoInPackage() {
    this.loading = true;
    let servicePackageCostToUse: any =
      this.selectedTabIndex == 0
        ? this.serviceToPackageCost
        : this.selected_optional_Services;
    this.newService
      .createService({
        service_cost: servicePackageCostToUse,
        package_id: this.dialogRecievedService.package.id,
        service_id: this.mappedService?.id,
      })
      .subscribe({
        next: (data) => {
          this.serviceToPackageCost = 0;
          this.mappedService = undefined;
          this.loading = false;
          this.fetchServiceCostInPackage();
        },
      });
  }

  editServiceCostInPackage(el: any) {
    this.loading = true;
    this.newService
      .updateService(el.id, {
        service_cost: this.serviceToPackageCost,
        package_id: this.dialogRecievedService.package.id,
        service_id: this.mappedService?.id,
      })
      .subscribe({
        next: (data) => {
          this.serviceToPackageCost = 0;
          this.mappedService = undefined;
          this.edit = false;
          this.fetchServiceCostInPackage();
        },
      });
  }
  deleteServiceCostInPackage(el: any, index: number) {
    if (confirm('Delete this record') == true) {
      let indexInSelectedService = this.selectedServices.findIndex(
        (data: any) => data.service_key === el.service.service_key
      );
      this.edit = true;
      this.selectedServices.splice(indexInSelectedService, 1);
      this.newService.removeService(el.id).subscribe({
        next: (data) => {
          this.upDatePackage();
        },
      });
    }
  }

  fetchServiceCostInPackage() {
    this.loading = true;
    this.newService
      .getServicePackagesAndCount(
        100,
        0,
        'ASC',
        this.dialogRecievedService.package?.package_key
      )
      .subscribe({
        next: (data: any) => {
          this.loading = false;
          this.accumulatedCostOfServicesInPackage = data.totalcost;
        },
      });
  }
}
