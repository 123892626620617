import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainComponent } from './main/main.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';


import { CardsComponent } from './components/cards/cards.component';
import { MaterialModule } from './shared.material.modules';
import { CreateServiceComponent } from './create-service/create-service.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ListCategoriesComponent } from './pages/list-categories/list-categories.component';
import { CreateCategoriesComponent } from './pages/create-categories/create-categories.component';
import { ListSubCategoriesComponent } from './pages/list-sub-categories/list-sub-categories.component';
import { CreateSubCategoriesComponent } from './pages/create-sub-categories/create-sub-categories.component';
import { ToastersComponent } from './components/toasters/toasters.component';
import { CreateServicesComponent } from './pages/create-services/create-services.component';
import { ViewSubCategoryComponent } from './pages/view-sub-category/view-sub-category.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import {
  ApiModule,
  Configuration,
  ConfigurationParameters,
} from './core/api/v1';

import { environment } from 'src/environments/environment';
import { KeycloakService } from 'keycloak-angular';
import { initializer } from 'AppInit';
import { AuthService } from './service/auth.service';
import { LoaderBarComponent } from './components/loader-bar/loader-bar.component';
import { CreateTestsComponent } from './pages/create-tests/create-tests.component';
import { ListTestsComponent } from './pages/list-tests/list-tests.component';
import { TestNavaComponent } from './test-nava/test-nava.component';
import { ListServicesComponent } from './pages/list-services/list-services.component';
import { NewPackagesComponent } from './pages/new-packages/new-packages.component';
import { MatInputModule } from '@angular/material/input';
import { ServiceDocumentsComponent } from './pages/service-documents/service-documents.component';
import { CreateDepartmentComponent } from './pages/dept/create-department/create-department.component';
// import { RadiologyModulesComponent } from './radiology-modules/radiology-modules.component';

export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    basePath: environment.basePath,
  };
  return new Configuration(params);
}

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    CardsComponent,
    CreateServiceComponent,
    ListCategoriesComponent,
    CreateCategoriesComponent,
    ListSubCategoriesComponent,
    CreateSubCategoriesComponent,
    ToastersComponent,
    CreateServicesComponent,
    ViewSubCategoryComponent,
    DashboardComponent,
    SidebarComponent,
    LoaderBarComponent,
    CreateTestsComponent,
    CreateTestsComponent,
    ListTestsComponent,
    TestNavaComponent,
    ListServicesComponent,
    NewPackagesComponent,
    ServiceDocumentsComponent,

    CreateDepartmentComponent,
  ],
  imports: [

    MaterialModule,
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatButtonModule,
    MatSidenavModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatInputModule,
    ApiModule.forRoot(apiConfigFactory),

  ],
  providers: [
    KeycloakService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [KeycloakService],
    },
    AuthService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
