import { KeycloakService, KeycloakOptions } from 'keycloak-angular';
import { environment } from 'src/environments/environment';

export function initializer(keycloak: KeycloakService): () => Promise<any> {
  const options: KeycloakOptions = {
    config: environment.keycloakConfig,
    initOptions: environment.initOptions,
    enableBearerInterceptor: true
  };

  return (): Promise<any> => {
    return keycloak.init(options);
  };
}