<div>

    <div style="margin-top:3%;">
        <h3>Service Details</h3>
        <mat-form-field class="example-full-width" appearance="outline">
            <input matInput placeholder="Service Name">
        </mat-form-field>
    </div>
    <hr>

    <h3>Sub Service Details</h3>
    <div style="padding:2%">

        <div class="row">
            <div class="col-md">

                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Sub Service Name</mat-label>
                    <input [(ngModel)]="test.test_name" matInput placeholder="Sub service name">
                </mat-form-field>
            </div>
            <div class="col-md">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Contains parameters</mat-label>
                    <mat-select [(ngModel)]="subtest_parameters">
                        <mat-option>Clear</mat-option>
                        <mat-option value=true>Yes</mat-option>
                        <mat-option value=false>No</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

        </div>


        <div class="row" *ngIf="subtest_parameters">
            <div class="col-md-6">
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput [(ngModel)]="test.parameters.name">
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-select [(ngModel)]="test.parameters.data_type">
                        <mat-option>Clear</mat-option>
                        <mat-option value="text">Text</mat-option>
                        <mat-option value="numbers_range">Range of numbers</mat-option>
                        <mat-option value="texts_range">Range of texts</mat-option>
                        <mat-option value="boolean">Positive - negative </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <h3>
            <span *ngFor="let item of test.parameters.values_num; let i=index">{{item}} - </span>
        </h3>
        <div class="row" *ngIf="test.parameters.data_type == 'numbers_range' ">
            <div class="col-md-6">

                <mat-form-field class="example-full-width " appearance="outline">
                    <input [(ngModel)]="currentRange" matInput placeholder="Start Value" type="number">
                </mat-form-field>
            </div>

            <div class="col-md-6">
                <!-- <button mat-raised-button (click)="addRangeNum()">Basic</button> -->
                <button class="button is-warning is-medium " (click)="addRangeNum()">Add range</button>
            </div>
        </div>
        <div class="row" *ngIf="test.parameters.data_type == 'texts_range' ">
            <div class="col-md-6">

                <mat-form-field class="example-full-width " appearance="outline">
                    <input [(ngModel)]="currentRange" matInput placeholder="Start Value" type="text">
                </mat-form-field>
            </div>

            <div class="col-md-6 ">
                <!-- <button mat-raised-button (click)="addRangeNum()">Basic</button> -->
                <button class="button is-warning is-medium " (click)="addRangeNum()">Add range</button>
            </div>
        </div>
        <div class="col-md-3">
            <button class="button is-primary is-medium is-fullwidth" (click)="addParameters()">Add Parameters</button>
        </div>
    </div>
    <hr>


</div>

<div class="card">
    <div class="card-body p-3">
        <h3>Semen Analysis</h3>
        <table class="table table-dark">
            <thead>
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Data</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of savedTest; let i=index">
                    <td>{{item.name}}</td>
                    <td>{{item.data_type}}</td>
                </tr>

            </tbody>
        </table>
    </div>
</div>