import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { HospitalServiceService } from '../services/hospital-service.service';
import { MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatDialog } from '@angular/material/dialog'
import { MatDialogRef} from "@angular/material/dialog"
import { CreateServiceComponent } from '../create-service/create-service.component';
import { AuthService } from '../service/auth.service';
import { Location } from '@angular/common'



@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
})
export class MainComponent {
  services: any = [];
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );


  constructor(
    private breakpointObserver: BreakpointObserver,
    private hservice: HospitalServiceService,
    public dialog: MatDialog,
    private authService: AuthService,
    private location: Location
  ) {}
  user:any
  ngOnInit(): void {
    this.user = this.authService.getLoggedUser();
    // this.hservice.getServices().subscribe((data) => {
    //   console.log(data);
    //   this.services = data.result;
    // });
  }

  
  back(): void {
    this.location.back()
  }

  logout() {
    this.authService.logout();
  }

  addService(): void {
    const dialogRef = this.dialog.open(CreateServiceComponent, {
      width: '950px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }
}
