import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { tap } from 'rxjs';
import { PackageServicesService } from 'src/app/core/api/v1/api/packageServices.service';
import { ServicesService } from 'src/app/core/api/v1/api/services.service';
// import {
//   PackageEntity,
//   PackageServicesService,
//   ServicesService,
// } from 'src/app/core/api/v1';
// import { TestServicesService } from 'src/app/core/api/v1/api/testServices.service';
// import { TestEntity } from 'src/app/core/api/v1/model/testEntity';
import { CreateTestsComponent } from '../create-tests/create-tests.component';

const ELEMENT_DATA: any[] = [
  { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
  { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He' },
  { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li' },
  { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be' },
  { position: 5, name: 'Boron', weight: 10.811, symbol: 'B' },
  { position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C' },
  { position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N' },
  { position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O' },
  { position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F' },
  { position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne' },
];

@Component({
  selector: 'app-list-tests',
  templateUrl: './list-tests.component.html',
  styleUrls: ['./list-tests.component.css'],
})
export class ListTestsComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    private service_service: ServicesService,
    private package_service: PackageServicesService
  ) {}
  displayedColumns: string[] = [
    'sn',
    'package_name',
    'package_key',
    'id',
    'action',
  ];
  dataSource: any;

  loading: boolean = false;
  tests: any[] = [];
  dataCount: number = 0;
  searchquery: string = '';

  @ViewChild(MatPaginator) paginator: MatPaginator | any;

  ngAfterViewInit() {
    this.paginator.page.pipe(tap(() => this.fetchPackagesPage())).subscribe();
  }

  ngOnInit(): void {
    this.fetchPackages();
  }

  fetchPackages() {
    this.loading = true;
    this.package_service
      .getPackagesAndCount(10, 0, 'DESC', undefined, this.searchquery)
      .subscribe((data: any) => {
        console.log(data, 'data');
        this.tests = data;
        this.dataCount = data.count;
        this.dataSource = new MatTableDataSource<any>(data.list);
        this.loading = false;
      });
  }

  fetchPackagesPage() {
    this.loading = true;
    this.package_service
      .getPackages(10, this.paginator.pageIndex, 'ASC')
      .subscribe((data: any) => {
        console.log(data, 'data');
        this.tests = data;
        this.dataSource = new MatTableDataSource<any>(data);
        this.loading = false;
      });
  }

  openDialog(data?: any) {
    const dialogRef = this.dialog.open(CreateTestsComponent, {
      width: '1200px',
      data: {
        package: data,
      },
    });

    // dialogRef.afterClosed().subscribe((result) => {
    //   console.log(`Dialog result: ${result}`);
    //   this.fetchPackages();
    // });
  }
}
