<div>
    <h2>Create Department</h2>

    <form #departmentForm="ngForm">
        <mat-form-field appearance="fill">
            <mat-label>Department Name</mat-label>
            <input matInput [(ngModel)]="newDepartment" name="department_name" required>
        </mat-form-field>
        <button mat-raised-button (click)="createDepartment()" color="primary" type="submit">Add Department</button>
    </form>

    <mat-table [dataSource]="departments" class="mat-elevation-z8">
        <ng-container matColumnDef="number">
            <mat-header-cell *matHeaderCellDef> No. </mat-header-cell>
            <mat-cell *matCellDef="let element; let i = index"> {{i + 1}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="department_name">
            <mat-header-cell *matHeaderCellDef> Department Name </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.department_name}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
            <mat-cell *matCellDef="let element">
                <button mat-icon-button color="primary" (click)="editDepartment(element.id, element)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button color="warn" (click)="deleteDepartment(element.id)">
                    <mat-icon>delete</mat-icon>
                </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
</div>
