<mat-progress-bar mode="indeterminate" *ngIf="loader"> </mat-progress-bar>
<div class="row">
  <div class="col-md">
    <mat-form-field appearance="outline">
      <mat-label>Select Category</mat-label>
      <mat-select [(ngModel)]="selected_category">
        <mat-option
          *ngFor="let cat of categories"
          [value]="cat.id"
          (click)="fetchSubCategory()"
        >
          {{ cat.category_name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-md">
    <mat-form-field appearance="outline">
      <mat-label>Select Sub Category</mat-label>
      <mat-select [(ngModel)]="selected_sub_category_id">
        <mat-option
          *ngFor="let sub of sub_category_details"
          [value]="sub.id"
          (click)="fetchServicesBySubCategories()"
        >
          {{ sub.sub_category_name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="col-md">
    <mat-form-field appearance="outline">
      <mat-label>Input</mat-label>
      <input
        matInput
        [(ngModel)]="searchquery"
        (keyup)="searchServices()"
        matInput
        placeholder="Search ..."
      />
    </mat-form-field>
  </div>
</div>

<table mat-table [dataSource]="services" class="">
  <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

  <!-- Position Column -->
  <!-- <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef> No. </th>
        <td mat-cell *matCellDef="let element"> </td>
    </ng-container> -->

  <!-- Name Column -->
  <ng-container matColumnDef="service_name">
    <th mat-header-cell *matHeaderCellDef>Service Name</th>
    <td mat-cell *matCellDef="let element">{{ element.service_name }}</td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="service_key">
    <th mat-header-cell *matHeaderCellDef>Service Key</th>
    <td mat-cell *matCellDef="let element">{{ element.service_key }}</td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="category">
    <th mat-header-cell *matHeaderCellDef>Category</th>
    <td mat-cell *matCellDef="let element">
      {{ element.sub_category?.category?.category_name }}
    </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="sub_category">
    <th mat-header-cell *matHeaderCellDef>Sub Category</th>
    <td mat-cell *matCellDef="let element">
      {{ element.sub_category?.sub_category_name }}
    </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef>Action</th>
    <td mat-cell *matCellDef="let element">
      <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
        aria-label="Example icon-button with a menu"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="addDocuments(element)">
          <span>Add Document</span>
        </button>
        <button mat-menu-item (click)="editService(element)">
          <span>Open</span>
        </button>
        <button mat-menu-item (click)="deleteService(element.id)">
          <span>Delete</span>
        </button>
      </mat-menu>
    </td></ng-container
  >

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<mat-paginator
  [length]="dataCount"
  [pageSize]="10"
  [pageSizeOptions]="[10, 15, 25]"
></mat-paginator>
