<h2>
    New Sub Category</h2>

<div class="row">
    <div class="col-md">
        <mat-form-field appearance="outline">
            <mat-label>Select Department</mat-label>
            <mat-select [(value)]="newSubCat.department">
                <mat-option *ngFor="let dept of departments" [value]="dept.id">
                    {{dept.department_name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-md">
        <mat-form-field appearance="outline">
            <mat-label>Select Category</mat-label>
            <mat-select [(value)]="newSubCat.category">
                <mat-option *ngFor="let cat of categories" [value]="cat.id">
                    {{cat.category_name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
<mat-form-field appearance="outline" style="width:100%;">
    <input matInput [(ngModel)]="newSubCat.sub_category_name" placeholder="sub category name">
</mat-form-field>

<div class="row">
    <div class="col-md">
        <!-- left -->
    </div>
    <div class="col-md-auto">
        <div class="row">
            <div class="example-button-container col-md">
                <button mat-button (click)="closeDialog()">Cancel</button>
            </div>
            <div class="example-button-container col-md">
                <button (click)="createSubCategory()" mat-button color="primary">Create</button>
            </div>
        </div>


    </div>
</div>