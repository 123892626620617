// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

interface InitOptions {
  onLoad: any;
  flow: string;
}

const initOptions: InitOptions = {
  onLoad: 'login-required',
  flow: 'standard',
};

export const environment = {
  production: false,
  baseUrl: 'http://localhost:5050/',
  basePath: "https://develop.drsavealife.com/v1/service-service",
  // basePath: 'http://localhost:5050',
  keycloakConfig: {
    clientId: 'dev-hospital-service-web-frontend',
    realm: 'SaveALifeSandbox',
    url: 'https://secure.balocodes.com.ng/auth',
  },
  initOptions: {
    onLoad: initOptions.onLoad,
    silentCheckSsoRedirectUri:
      window.location.origin + '/assets/silent-check-sso.html',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

