<div class="m-3">
  <div class="center-heading">Result Templates</div>

  <div class="mb-5">
    <input class="form-control form-control-lg" id="formFileLg" type="file" />
  </div>

  <div class="" style="width: 18rem">
    <img
      class="card-img-top"
      src="../../../assets/pdffiles.png"
      alt="Card image cap"
    />
    <div class="card-body">
      <p class="card-text">card's content.</p>
      <a href="#" class="card-link">Download and view</a>
      <a href="#" class="card-link">Delete file</a>
    </div>
  </div>
</div>
