<mat-progress-bar mode="indeterminate" *ngIf="loading">
</mat-progress-bar>


<h1 class="mt-5">
    Tests Packages</h1>

<div class="row">
    <div class="col-md">
        <button color="primary" (click)="openDialog()" mat-mini-fab>
            <mat-icon  >add</mat-icon>
          </button>
    </div>
    <div class="col-md-auto">

    </div>

</div>

<!-- <div class="row mt-3">
    <div class="col-md-3 mt-2  " *ngFor="let item of tests">
        <div class="card pointer border-primary" (click)="openDialog(item)">
            <h2 class="card-body text-primary">
                {{item.package_name}}

            </h2>

        </div>
    </div>
</div> -->

<div class="col-md">
    <mat-form-field appearance="outline">
        <mat-label>Search Query</mat-label>
        <input matInput [(ngModel)]="searchquery" (keyup)="fetchPackages()" matInput placeholder="Search ..." matInput>
    </mat-form-field>
</div>

<table mat-table [dataSource]="dataSource" class=" mt-3">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- Position Column -->
    <ng-container matColumnDef="sn">
        <th mat-header-cell *matHeaderCellDef> No. </th>
        <td mat-cell *matCellDef="let element; let i = index;"> {{i+1}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="package_name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element"> {{element.package_name}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="package_key">
        <th mat-header-cell *matHeaderCellDef> Key </th>
        <td mat-cell *matCellDef="let element"> {{element.package_key}} </td>
    </ng-container>

    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> Test ID </th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element">
            <button mat-icon-button aria-label="Example icon-button with a menu" (click)="openDialog(element)">
            <mat-icon  style="cursor: pointer; color: rgba(32, 164, 243);
            ;">more_vert</mat-icon>
          </button> </td>
    </ng-container>




    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator [length]="dataCount" [pageSize]="10" [pageSizeOptions]="[10, 15, 25]"></mat-paginator>