import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs';
import { ServicesService } from 'src/app/core/api/v1/api/services.service';
import { SubCategoryService } from 'src/app/core/api/v1/api/subCategory.service';
// import { ServicesService, SubCategoryService } from 'src/app/core/api/v1';
import { HospitalServiceService } from 'src/app/services/hospital-service.service';
import { CreateServicesComponent } from './../create-services/create-services.component';
import { ServiceDocumentsComponent } from '../service-documents/service-documents.component';

@Component({
  selector: 'app-view-sub-category',
  templateUrl: './view-sub-category.component.html',
  styleUrls: ['./view-sub-category.component.css'],
})
export class ViewSubCategoryComponent implements OnInit {
  sub_category: number = 0;
  sub_category_details: any;
  category_id: number = 0;
  services: any;
  loader: boolean = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private sub_category_service: SubCategoryService,
    private service_service: ServicesService
  ) {
    this.activatedRoute.queryParams.subscribe((data) => {
      this.sub_category = data['sub_category'];
      this.category_id = data['category_id'];

      console.log(this.category_id, 'cat id');
    });
  }

  displayedColumns: string[] = ['service_name', 'service_key', 'action'];
  dataCount: number = 0;

  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  ngAfterViewInit() {
    this.paginator.page
      .pipe(tap(() => this.fetchServicesBySubCategoriesPage()))
      .subscribe();
  }

  ngOnInit(): void {
    this.fetchServicesBySubCategories();
    this.fetchSubCategory();
  }

  fetchServicesBySubCategories() {
    this.loader = true;
    this.service_service
      .getServicesAndCount(undefined, 0, 'DESC', this.sub_category)
      .subscribe((data: any) => {
        console.log(data, 'services services');
        this.loader = false;
        this.services = new MatTableDataSource<any>(data.result.list);
        this.dataCount = data.result.count;
      });
  }

  fetchServicesBySubCategoriesPage() {
    // this.loading = true;
    this.service_service
      .getServicesAndCount(
        10,
        this.paginator.pageIndex,
        'DESC',
        this.sub_category,
        undefined,
        undefined,
        undefined,
        undefined
      )
      .subscribe((data: any) => {
        console.log(data, 'data');
        this.services = new MatTableDataSource<any>(data.result.list);
        this.dataCount = data.result.count;
        this.loader = false;
      });
  }
  fetchSubCategory() {
    this.sub_category_service
      .getSingleSubCategory(this.sub_category)
      .subscribe((data: any) => {
        console.log(data.data, 'sub category details');
        this.sub_category_details = data;
      });
  }

  deleteService(id: number) {
    if (confirm('Are you sure to delete ' + name)) {
      console.log('delete?');
      this.service_service.removeService(id).subscribe((data: any) => {
        this.fetchServicesBySubCategories();
      });
    }
  }

  editFields(service: any) {
    console.log();
    service.category_id = this.category_id;
    this.dialog.open(CreateServicesComponent, {
      // width: '1500px',
      // height: '700px',
      data: {
        sub_cat: this.sub_category,
        service: service,
        sub_category_key: this.sub_category_details.data.sub_category_key,
      },
    });
  }

  addDocuments(service: any) {
    console.log();
    this.dialog.open(ServiceDocumentsComponent, {
      data: {
        service: service,
      },
    });
  }

  createService() {
    let dialogRef = this.dialog.open(CreateServicesComponent, {
      // width: '1500px',
      // height: '700px',

      data: {
        sub_cat: this.sub_category,
        sub_category_key: this.sub_category_details.data.sub_category_key,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result, 'here is the result');
      // this.results.result.values = result
      // this.saveResult();
      this.fetchServicesBySubCategories();
      this.fetchSubCategory();
    });
  }
}
