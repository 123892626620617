import { Component, OnInit, Inject } from '@angular/core';
import { HospitalServiceService } from './../../services/hospital-service.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import {
  combo_nine,
  default_Culture,
  default_Microscopy,
  default_sensitivity,
} from './data';

import { ActivatedRoute } from '@angular/router';
import { HospitalServices } from 'src/app/core/api/v1/model/hospitalServices';
import { ServicesService } from 'src/app/core/api/v1/api/services.service';
import { DepartmentsService } from 'src/app/core/api/v1/api/departments.service';
import { ServiceType } from 'src/app/core/api/v1/model/serviceType';
import { Department } from 'src/app/core/api/v1/model/department';
import { HospitalServicesFindings } from 'src/app/core/api/v1/model/hospitalServicesFindings';
import { HospitalServicesAgeRange } from 'src/app/core/api/v1/model/hospitalServicesAgeRange';
import { HospitalServicesFields } from 'src/app/core/api/v1/model/hospitalServicesFields';
import { HospitalServicesExtension } from 'src/app/core/api/v1/model/hospitalServicesExtension';
import { HospitalServicesPresetsData } from 'src/app/core/api/v1/model/hospitalServicesPresetsData';
import { HospitalServicesSubFields } from 'src/app/core/api/v1/model/hospitalServicesSubFields';
import { HospitalServicesSpecialCostHmoCosts } from 'src/app/core/api/v1/model/hospitalServicesSpecialCostHmoCosts';
import { HospitalServicesSpecialCostPackagesCost } from 'src/app/core/api/v1/model/hospitalServicesSpecialCostPackagesCost';
import { HospitalServicesSpecialCost } from 'src/app/core/api/v1/model/hospitalServicesSpecialCost';
import { HospitalServicesForm } from 'src/app/core/api/v1/model/hospitalServicesForm';
import { HospitalServicesFields1 } from 'src/app/core/api/v1/model/hospitalServicesFields1';
import { Sex, SubCategory, SubCategoryService } from 'src/app/core/api/v1';
import { ServiceAccessType } from 'src/app/core/api/v1/model/serviceAccessType';
import { MatSnackBar } from '@angular/material/snack-bar';

type FieldType = {
  value: string;
  viewValue: string;
};

@Component({
  selector: 'app-create-services',
  templateUrl: './create-services.component.html',
  styleUrls: ['./create-services.component.css'],
})
export class CreateServicesComponent implements OnInit {
  constructor(
    private hservice: HospitalServiceService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA)
    public recievedToDialogdata: {
      service: any;
      sub_cat: number;
      sub_category_key: string;
    },
    public dialogRef: MatDialogRef<CreateServicesComponent>,
    private service_service: ServicesService,
    private sub_category: SubCategoryService,
    private departmentService: DepartmentsService,
    private activatedRoute: ActivatedRoute
  ) {
    this.service_type = ServiceType;
    this.activatedRoute.queryParams.subscribe((data) => {
      this.category_key = data['category'];
    });

    console.log(recievedToDialogdata, 'recievedToDialogdata');

    if (recievedToDialogdata.service) {
      this.newData = recievedToDialogdata.service;
      delete this.newData.createdAt, delete this.newData.updatedAt;
    }
    if (recievedToDialogdata.service?.service_type) {
      this.selected_service_type = recievedToDialogdata.service
        ?.service_type as any;
    }
    if (recievedToDialogdata.service?.sex) {
      this.selected_service_sex = recievedToDialogdata.service?.sex as any;
    }
    if (recievedToDialogdata.service?.accessType) {
      this.selected_access_type = recievedToDialogdata.service?.accessType;
    }
    if (recievedToDialogdata?.service?.special_cost == null) {
      this.specialCost =
        recievedToDialogdata.service?.special_cost || this.specialCost;
    }
    if (recievedToDialogdata?.service?.findings) {
      this.findings = recievedToDialogdata?.service.findings;
    }
    if (recievedToDialogdata?.service?.department_route) {
      this.department_route = recievedToDialogdata?.service.department_route;
    }

    if (recievedToDialogdata.sub_cat) {
      this.newData.sub_category = this.recievedToDialogdata.sub_cat;
      this.newData.sub_category_key =
        this.recievedToDialogdata.sub_category_key;
    }
  }

  ngOnInit(): void {
    this.fetchDepartments();
    this.fetchSubCategories();
  }

  service_type!: { Advanced: ServiceType; Basic: ServiceType };
  service_sex: Sex[] = ['both', 'female', 'male'];
  sub_categories: any = [];
  service_access_type: { title: string; value: ServiceAccessType }[] = [
    {
      title: 'All',
      value: ServiceAccessType.All,
    },
    {
      title: 'Only Staff',
      value: ServiceAccessType.OnlyStaff,
    },
    {
      title: 'Only Patients',
      value: ServiceAccessType.OnlyPatients,
    },
  ];
  column_name: string = '';
  department_route: string = '';
  department_route_service: string = 'none';
  category_key: any = '';
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  extension_range: any = [];
  field_range: any = [];
  departments: Department[] = [];
  findings: HospitalServicesFindings[] = [];
  age_range: HospitalServicesAgeRange = {
    min_age: 0,
    max_age: 0,
  };

  row_name: string = '';
  field: HospitalServicesFields | any = {
    key: '',
    title: '',
    fieldType: 'number',
    range: [],
    range_female: { upper: null, lower: null, general: '', unit: '' },
    range_male: { upper: 0, lower: 0, general: '', unit: '' },
    range_children: { upper: 0, lower: 0, general: '', unit: '' },
    sub_fields: [],
    fieldsHeadingTitle: '',
  };

  foods: any[] = [
    { value: 'steak-0', viewValue: 'Steak' },
    { value: 'pizza-1', viewValue: 'Pizza' },
    { value: 'tacos-2', viewValue: 'Tacos' },
  ];

  special_groups: any = [
    { value: 'sensitivity', viewValue: 'Sensitivity' },
    { value: 'culture', viewValue: 'Culture' },
    { value: 'microscopy', viewValue: 'Microscopy' },
    { value: 'combo9', viewValue: 'Combo 9' },
  ];
  extension: HospitalServicesExtension = {
    key: '',
    title: '',
    type: 'input',
    range: [],
  };

  preset_data: HospitalServicesPresetsData = {
    preset_key: '',
    preset_title: '',
    report: '',
    fields: [],
    form: [],
  };

  global_extension: any = [];

  sub_fields: HospitalServicesSubFields = {
    key: '',
    title: '',
    extended: false,
    extension: [],
  };
  global_sub_fields: any = [];

  extensionsState = [
    { value: 'false', viewValue: 'False' },
    { value: 'true', viewValue: 'True' },
  ];

  selectedExtensionState = false;

  extensions = [
    { value: 'number', viewValue: 'Numbers only' },
    { value: 'radio', viewValue: 'Radio Buttons / single select' },
    { value: 'checkbox', viewValue: 'Check Box / multiple select' },
    { value: 'input', viewValue: 'Text' },
    { value: 'text_area', viewValue: 'Large Text (Notes)' },
    { value: 'boolean', viewValue: 'Boolean - Postive / Negative' },
    { value: 'datetime-local', viewValue: 'Date' },
    { value: 'time', viewValue: 'Time' },
    { value: 'range', viewValue: 'Range Of Values' },
  ];
  selectedExtension = 'radio';

  optionsType = [
    { value: 'number', viewValue: 'Numbers only' },
    { value: 'radio', viewValue: 'Radio Buttons / single select' },
    { value: 'checkbox', viewValue: 'Check Box / multiple select' },
    { value: 'input', viewValue: 'Text' },
    { value: 'text_area', viewValue: 'Large Text (Notes)' },
    { value: 'boolean', viewValue: 'Boolean - Postive / Negative' },
    { value: 'datetime-local', viewValue: 'Date' },
    { value: 'time', viewValue: 'Time' },
    { value: 'range', viewValue: 'Range Of Values' },
  ];
  selectedOptions = 'radio';

  selected_service_type!: ServiceType;
  selected_service_sex!: Sex;
  selected_access_type: ServiceAccessType = ServiceAccessType.All;

  selected_field_type = 'number';
  fieldTypes: FieldType[] = [
    { value: 'number', viewValue: 'Numbers only' },
    { value: 'radio', viewValue: 'Radio Buttons / single select' },
    { value: 'checkbox', viewValue: 'Check Box / multiple select' },
    { value: 'input', viewValue: 'Text' },
    { value: 'text_area', viewValue: 'Large Text (Notes)' },
    { value: 'boolean', viewValue: 'Boolean - Postive / Negative' },
    { value: 'datetime-local', viewValue: 'Date' },
    { value: 'time', viewValue: 'Time' },
    { value: 'range', viewValue: 'Range Of Values' },
    { value: 'measurement', viewValue: 'Measurement' },
    {
      value: 'table-sensitivity',
      viewValue: 'Sensitivity',
    },
    { value: 'table-widal', viewValue: 'Widal Test' },
  ];

  showhmodetails: boolean = false;
  hmo_details: HospitalServicesSpecialCostHmoCosts = {
    hmo_name: '',
    hmo_key: '',
    cost: 0,
  };

  showpackagescost: boolean = false;
  packages_details: HospitalServicesSpecialCostPackagesCost = {
    package_name: '',
    package_key: '',
    cost: 0,
  };

  specialCost: HospitalServicesSpecialCost = {
    hmo_costs: [],
    packages_cost: [],
  };

  newData: HospitalServices | any = {
    service_name: '',
    sub_category: 0,
    service_cost: 0,
    description: '',
    percentage_discount: 10,
    fields: [],
    preset: false,
    presets_data: [],
  };

  form: HospitalServicesForm = {
    type: 'checkbox',
    key: '',
    title: '',
    unit: '',
    fields: [],
  };

  field1: HospitalServicesFields1 = {
    title: '',
    key: '',
  };

  singlefindings: HospitalServicesFindings = {
    title: '',
    key: '',
    value: '',
  };

  fetchDepartments() {
    this.departmentService
      .getAllDepartment(undefined, 0)
      .subscribe((data: any) => {
        this.departments = data.data;
      });
  }
  fetchSubCategories() {
    this.sub_category.getAllSubCategories(undefined, 100).subscribe((data) => {
      console.log(data);
      this.sub_categories = data.data;
    });
  }
  changeSubCategory(data: SubCategory) {
    this.newData.sub_category = data.id;
    this.newData.sub_category_key = data.sub_category_key;
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.extension_range.push(value);
    }
    event.chipInput!.clear();
  }

  remove(range: any): void {
    const index = this.extension_range.indexOf(range);
    if (index >= 0) {
      this.extension_range.splice(index, 1);
    }
  }

  addFieldRange(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.field.range.push(value);
    }
    event.chipInput!.clear();
  }

  removeFieldRange(fruit: any): void {
    const index = this.field.range.indexOf(fruit);
    if (index >= 0) {
      this.field.range.splice(index, 1);
    }
  }

  addExtensions() {
    let extension = {
      key: this.hservice.generate_keys(
        this.extension.title,
        this.sub_fields.title
      ),
      title: this.extension.title,
      type: this.extension.type,
      range: this.extension_range,
    };
    this.global_extension.push(extension);
    this.extension = { key: '', title: '', type: 'input', range: [] };
    this.selectedExtension = 'radio';
  }

  deleteExtension(data: any) {
    const extensionIndex = this.global_extension.findIndex(
      ({ key }: any) => key === data.key
    );
    this.global_extension.splice(extensionIndex, 1);
  }

  addSubFields() {
    let sub_fields: any = {
      key: this.hservice.generate_keys(this.sub_fields.title, this.field.title),
      title: this.sub_fields.title,
      extended: this.selectedExtensionState,
      extension: this.global_extension,
    };

    this.global_sub_fields.push(sub_fields);
    this.global_extension = [];
    this.sub_fields = { key: '', title: '', extended: false, extension: [] };
  }

  deleteSubFields(data: any) {
    const fieldIndex = this.global_sub_fields.findIndex(
      ({ key }: any) => key === data.key
    );
    this.global_sub_fields.splice(fieldIndex, 1);
  }

  addHMOCost() {
    let hmo_data = {
      cost: this.hmo_details.cost,
      hmo_key: this.hmo_details.hmo_key,
      hmo_name: this.hmo_details.hmo_name,
    };
    this.specialCost.hmo_costs?.push(hmo_data);
  }
  editHMOCost(data: any) {
    this.hmo_details = data;
  }

  deleteHMOCost(i: any) {
    this.specialCost.hmo_costs?.splice(i, 1);
  }

  addPackagesCost() {
    let pkgs_data = {
      cost: this.packages_details.cost,
      package_key: this.packages_details.package_key,
      package_name: this.packages_details.package_name,
    };
    this.specialCost.packages_cost?.push(pkgs_data);
    // this.packages_details = { cost: 0 };
  }

  editPackagesCost(data: any) {
    this.packages_details = data;
  }

  deletePackagesCost(i: any) {
    this.specialCost.packages_cost?.splice(i, 1);
  }
  addFields() {
    let field: HospitalServicesFields | any = {
      key: this.field.key,
      title: this.field.title,
      fieldType: this.field.fieldType,
      range: this.field.range,
      sub_fields: this.global_sub_fields,

      range_female: {
        upper: this.field?.range_female?.upper,
        lower: this.field.range_female?.lower,
        general: this.field.range_female?.general,
        unit: this.field.range_female?.unit,
      },
      range_male: {
        upper: this.field.range_male?.upper,
        lower: this.field.range_male?.lower,
        general: this.field.range_male?.general,
        unit: this.field.range_male?.unit,
      },
      range_children: {
        upper: this.field.range_male?.upper,
        lower: this.field.range_male?.lower,
        general: this.field.range_male?.general,
        unit: this.field.range_male?.unit,
      },
    };

    let data = this.newData.service_name + ' ' + this.field.title;
    field.key = this.hservice.generateService(data);
    this.newData?.fields?.push(field);
    this.global_sub_fields = [];
  }

  removeField(data: any) {
    const fieldIndex: any = this.newData.fields?.findIndex(
      ({ key }: any) => key === data.key
    );
    this.newData.fields?.splice(fieldIndex, 1);
  }

  editField(data: any) {
    this.field = data;
  }

  editSubField(data: any) {
    this.sub_fields = data;
    this.extension = data.extension;
  }

  addPreset() {
    // let id = this.newData.id;
    // delete this.newData.id;
    this.newData.service_key = this.hservice.generateService(
      this.newData.service_name
    );
    this.newData.category = 3;
    this.newData.category_key = this.category_key;
    this.newData.preset = true;
    let newPreset = {
      fields: this.preset_data.fields,
      preset_key: this.hservice.generate_keys(this.preset_data.preset_title),
      preset_title: this.preset_data.preset_title,
      report: this.preset_data.report,
      form: this.preset_data.form,
    };
    this.newData?.presets_data?.push(newPreset);
    this.preset_data.fields = [];
  }

  removePreset(index: number) {
    this.newData.presets_data?.splice(index, 1);
  }

  editPreset(data: any) {
    this.preset_data = data;
  }

  addFormFields() {
    let newField = {
      key: this.hservice.generate_keys(
        this.preset_data.preset_title,
        this.form.title,
        this.field1.title
      ),
      title: this.field1.title,
    };
    this.form.fields.push(newField);
    this.field = {};
  }
  removeFormFields(index: any) {
    this.form.fields.splice(index, 1);
  }
  editFormsFields(data: any) {
    this.field1 = data;
  }

  addFindings() {
    let newFindings: HospitalServicesFindings = {
      key: this.singlefindings.key,
      value: this.singlefindings.value,
      title: this.singlefindings.title,
    };
    this.findings.push(newFindings);
    this.singlefindings = {
      title: '',
      value: '',
      key: '',
    };
  }
  removeFindings(i: number) {
    this.findings.splice(i, 1);
  }
  editFindings(i: number) {}

  addForm() {
    let newForm = {
      key: this.hservice.generate_keys(
        this.preset_data.preset_title,
        this.form.title
      ),
      fields: this.form.fields,
      type: this.form.type,
      title: this.form.title,
    };
    this.preset_data.form?.push(newForm);
    this.form.fields = [];
    this.form.key = '';
    this.form.key = '';
    this.form.type = 'checkbox';
  }

  removeForm(index: any) {
    this.preset_data.form?.splice(index, 1);
  }

  editForm(data: any) {
    this.form = data;
  }

  add_groupedData(data_type: string) {
    switch (data_type) {
      case 'microscopy':
        default_Microscopy.forEach((element) => {
          this.newData?.fields?.push(element);
        });
        break;
      case 'culture':
        default_Culture.forEach((element) => {
          this.newData?.fields?.push(element);
        });
        break;
      case 'sensitivity':
        this.newData?.fields?.push(default_sensitivity[0]);
        break;

      case 'combo9':
        combo_nine.forEach((element) => {
          this.newData?.fields?.push(element);
        });
        break;
      default:
        break;
    }
  }

  createService() {
    let id = this.newData.id;
    delete this.newData.id;
    delete this.newData.slug;
    delete this.newData.category_id;
    this.newData.service_key = this.hservice.generateService(
      this.newData.service_name
    );
    this.newData.category;
    this.newData.age_range = this.age_range;
    this.newData.category_key = this.category_key;
    this.newData.special_cost = this.specialCost;
    this.newData.service_type = this.selected_service_type;
    this.newData.sex = this.selected_service_sex;
    this.newData.accessType = this.selected_access_type;
    this.newData.department_route = this.department_route;
    this.newData.findings = this.findings;
    this.newData.findings = this.findings;
    if (this.newData.description === null) {
      this.newData.description = '';
    }

    delete this.newData.deletedAt;

    if (this.newData.sub_category == 0) {
      this.newData.sub_category = Number(
        this.recievedToDialogdata.service.sub_category
      );
      this.newData.sub_category_key =
        this.recievedToDialogdata.sub_category_key || '';
    }

    if (id) {
      console.log(this.newData, 'new data to edit');
      this.service_service.updateService(id, this.newData).subscribe({
        next: (data: any) => {
          this.dialogRef.close(data);
          this.newData.id = id;
          this._snackBar.open('updated successfully');
        },
        error: (e: any) => {
          this.newData.id = id;
          this._snackBar.open('error in updating service');
        },
      });
    } else {
      this.service_service.createService(this.newData).subscribe({
        next: (data) => {
          this._snackBar.open('saved successfully');
        },
        error: (error) => {
          this._snackBar.open('error in saving service');
        },
      });

      // .subscribe((data: any) => {
      //   this.dialogRef.close(data);
      // });
    }
  }
}
