


export interface IHmoDetails  {
  hmo_name: string,
  hmo_key: string,
  cost: number,
}



export const microorganisms = [
  'Staphylococcus epidermidis',
  'Staphylococcus lugdunensis',
  'Staphylococcus saprophyticus',
  'Staphylococcus hominis',
  'Streptococcus pyogenes',
  'Streptococcus agalactiae',
  'Streptococcus anginosus',
  'Streptococcus bovis',
  'Streptococcus pneumoniae',
  'Streptococcus equinus',
  'Streptococcus faecalis',
  'Streptococcus avium',
  'Streptococcus lactis',
  'Streptococcus morbillorum',
  'Streptococcus salivarius',
  'Streptococcus sanguis',
  'Streptococcus oralis',
  'Streptococcus mutans',
  'Corynebacterium diphtheriae',
  'Corynebacterium haemolyticum',
  'Corynebacterium xerosis',
  'Corynebacterium pseudotuberculosis',
  'Corynebacterium ulcerans',
  'Mycobacterium tuberculosis',
  'Mycobacterium leprae',
  'Mycobacterium bovis',
  'Bacillus cereus',
  'Bacillus anthracis',
  'Clostridium tetani',
  'Clostridium difficile',
  'Clostridium perfringens',
  'Clostridium botulinum',
  'Clostridium septicum',
  'Clostridium novyi',
  'Clostridium histolyticum',
  'Clostridium fallax',
  'Actinomyces israelii',
  'Actinomyces bovis',
  'Actinomyces naeslundi',
  'Nocardia asteroids',
  'Nocardia brasiliensis',
  'Nocardia caviae',
  'Actinomadura madurae',
  'Streptomyces somaliensis',
  'Actinomadurapelletieri',
  'Neisseria meningitidis',
  'Neisseria gonorrhoeae',
  'Moraxella catarrhalis',
  'Haemophilus influenzae',
  'Haemophilus aegyptius',
  'Haemophilus ducreyi',
  'Haemophilus haemolyticus',
  'Haemophilus aphrophilus',
  'Haemophilus parainfluenzae',
  'Haemophilus parahaemolyticus ',
  'Gardnerella vaginalis',
  'Bordetella pertussis',
  'Bordetella parapertussis',
  'Bordetella bronchiseptica',
  'Brucellae abortus',
  'Brucellae melitensis',
  'Brucellae suis',
  'Brucellae canis',
  'Klebsiella pneumonia',
  'Klebsiella aerogenes',
  'Klebsiella rhinoscleromatis',
  'Klebsiella oxytoca',
  'Klebsiella granulomatis',
  'Pseudomonas aeruginosa',
  'Pseudomonas maltophilia',
  'Proteus vulgaris',
  'Proteus mirabilis',
  'Yersinia pestis',
  'Yersinia pseudotuberculosis',
  'Yersinia enterocolitica',
  'Bacteroides fragilis',
  'Salmonella bongori',
  'Salmonella enterica',
  'Shigella dysenteriae',
  'Shigella sonnei',
  'Shigella flexneri',
  'Shigella boydii',
  'Campylobacter jejuni',
  'Campylobacter coli',
  'Campylobacter lari',
  'Campylobacter upsaliensis',
  'Enterococcus faecalis',
  'Enterococcus faecium',
  'Bartonella bacilliformis',
  'Escherichia coli',
  'Morganella morganii',
  'Vibrio cholera',
  'Vibrio mimicus',
  'Vibrio fluvialis',
  'Vibrio parahaemolyticus',
  'Vibrio alginolyticus',
  'Vibrio vulnificus',
  'Providencia stuartii',
  'Providencia rettgeri',
  'Providencia alcalifaciens',
  'Providencia rustigianii',
  'Providencia heimbachae',
];

export const default_sensitivity = [
  {
    key: 'sensitivity',
    title: 'Sensitivity',
    grouped_fields: 'sensitivity',
    fieldType: 'table-sensitivity',
    range_male: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_female: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_children: {
      unit: '',
      lower: 0,
      upper: 0,
    },
  },
];

export const default_Culture = [
  {
    key: 'temperature',
    title: 'Temperature',
    grouped_fields: 'culture',
    fieldType: 'range',
    range: [28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38],
    range_male: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_female: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_children: {
      unit: '',
      lower: 0,
      upper: 0,
    },
  },
  {
    key: 'microorganisms',
    title: 'Microorganisms',
    grouped_fields: 'culture',
    fieldType: 'range',
    range: microorganisms,
    range_male: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_female: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_children: {
      unit: '',
      lower: 0,
      upper: 0,
    },
  },
  {
    key: 'yielded',
    title: 'Yielded',
    grouped_fields: 'culture',
    fieldType: 'range',
    range: ['Nil', 'Scanty', 'Moderate', 'Heavy'],
    range_male: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_female: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_children: {
      unit: '',
      lower: 0,
      upper: 0,
    },
  },
  {
    key: 'incubation_time',
    title: 'Incubation Time',
    grouped_fields: 'culture',
    fieldType: 'range',
    range: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 232, 4,
    ],
    range_male: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_female: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_children: {
      unit: '',
      lower: 0,
      upper: 0,
    },
  },
];
export const default_Microscopy = [
  {
    key: 'appearance',
    title: 'Appearance',
    grouped_fields: 'microscopy',
    fieldType: 'input',
    range_male: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_female: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_children: {
      unit: '',
      lower: 0,
      upper: 0,
    },
  },
  {
    key: 'pus_cells',
    title: 'Pus Cells',
    grouped_fields: 'microscopy',
    fieldType: 'input',
    range_male: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_female: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_children: {
      unit: '',
      lower: 0,
      upper: 0,
    },
  },
  {
    key: 'epith_cells',
    title: 'Epith Cells',
    grouped_fields: 'microscopy',
    fieldType: 'input',
    range_male: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_female: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_children: {
      unit: '',
      lower: 0,
      upper: 0,
    },
  },
  {
    key: 'easts',
    title: 'Easts',
    grouped_fields: 'microscopy',
    fieldType: 'input',
    range_male: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_female: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_children: {
      unit: '',
      lower: 0,
      upper: 0,
    },
  },
  {
    key: 'gram_positive_cocci',
    title: 'Gram Positive Cocci',
    grouped_fields: 'microscopy',
    fieldType: 'input',
    range_male: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_female: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_children: {
      unit: '',
      lower: 0,
      upper: 0,
    },
  },
  {
    key: 'gram_positive_rods',
    title: 'Gram Positive Rods',
    grouped_fields: 'microscopy',
    fieldType: 'input',
    range_male: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_female: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_children: {
      unit: '',
      lower: 0,
      upper: 0,
    },
  },
  {
    key: 'gram_negative_cocci',
    title: 'Gram Negative Cocci',
    grouped_fields: 'microscopy',
    fieldType: 'input',
    range_male: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_female: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_children: {
      unit: '',
      lower: 0,
      upper: 0,
    },
  },
  {
    key: 'gram_negative_rods',
    title: 'Gram Negative Rods',
    grouped_fields: 'microscopy',
    fieldType: 'input',
    range_male: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_female: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_children: {
      unit: '',
      lower: 0,
      upper: 0,
    },
  },
  {
    key: 'yeasts',
    title: 'Yeasts',
    grouped_fields: 'microscopy',
    fieldType: 'input',
    range_male: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_female: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_children: {
      unit: '',
      lower: 0,
      upper: 0,
    },
  },
  {
    key: 'trichomonas',
    title: 'Trichomonas',
    grouped_fields: 'microscopy',
    fieldType: 'input',
    range_male: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_female: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_children: {
      unit: '',
      lower: 0,
      upper: 0,
    },
  },
  {
    key: 'others',
    title: 'Others',
    grouped_fields: 'microscopy',
    fieldType: 'input',
    range_male: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_female: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_children: {
      unit: '',
      lower: 0,
      upper: 0,
    },
  },
];

export const combo_nine = [
  {
    key: 'lenkocyte',
    title: 'Lenkocyte',
    grouped_fields: 'microscopy',
    fieldType: 'range',
    range: [
     'Nil', 'Trace', '+', '++', '+++'
    ],
    range_male: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_female: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_children: {
      unit: '',
      lower: 0,
      upper: 0,
    },
  },
  {
    key: 'nitrite',
    title: 'Nitrite',
    grouped_fields: 'microscopy',
    fieldType: 'range',
    range: [
     'Nil', 'Positive'
    ],
    range_male: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_female: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_children: {
      unit: '',
      lower: 0,
      upper: 0,
    },
  },
  {
    key: 'urobilirogen',
    title: 'Urobilirogen',
    grouped_fields: 'microscopy',
    fieldType: 'range',
    range: [
     'Nil', 'Trace', '+', '++', '+++'
    ],
    range_male: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_female: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_children: {
      unit: '',
      lower: 0,
      upper: 0,
    },
  },
  {
    key: 'Protein',
    title: 'protien',
    grouped_fields: 'microscopy',
    fieldType: 'range',
    range: [
     'Nil', 'Trace', '+', '++', '+++'
    ],
    range_male: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_female: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_children: {
      unit: '',
      lower: 0,
      upper: 0,
    },
  },
  {
    key: 'PH',
    title: 'ph',
    grouped_fields: 'microscopy',
    fieldType: 'range',
    range: [
     5.0, 6.0, 7.0, 8.0,9.0
    ],
    range_male: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_female: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_children: {
      unit: '',
      lower: 0,
      upper: 0,
    },
  },
  {
    key: 'SG',
    title: 'sg',
    grouped_fields: 'microscopy',
    fieldType: 'range',
    range: [
     1.005, 1.010, 1.015, 1.020, 1.025, 1.030
    ],
    range_male: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_female: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_children: {
      unit: '',
      lower: 0,
      upper: 0,
    },
  },
  {
    key: 'Blood',
    title: 'blood',
    grouped_fields: 'microscopy',
    fieldType: 'range',
    range: [
     'Nil', 'Trace', '+', '++', '+++'
    ],
    range_male: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_female: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_children: {
      unit: '',
      lower: 0,
      upper: 0,
    },
  },
  {
    key: 'Ketone',
    title: 'ketone',
    grouped_fields: 'microscopy',
    fieldType: 'range',
    range: [
     'Nil', 'Trace', '+', '++', '+++'
    ],
    range_male: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_female: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_children: {
      unit: '',
      lower: 0,
      upper: 0,
    },
  },
  {
    key: 'Bilirobin',
    title: 'bilirobin',
    grouped_fields: 'microscopy',
    fieldType: 'range',
    range: [
     'Nil', 'Trace', '+', '++', '+++'
    ],
    range_male: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_female: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_children: {
      unit: '',
      lower: 0,
      upper: 0,
    },
  },
  {
    key: 'Glucose',
    title: 'glucose',
    grouped_fields: 'microscopy',
    fieldType: 'range',
    range: [
     'Nil', 'Trace', '+', '++', '+++'
    ],
    range_male: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_female: {
      unit: '',
      lower: 0,
      upper: 0,
    },
    range_children: {
      unit: '',
      lower: 0,
      upper: 0,
    },
  },
];
