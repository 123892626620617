import { Component, OnInit } from '@angular/core';
import { DepartmentsService } from '../../../core/api/v1';
import { Department } from '../../../core/api/v1/model/department';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-create-department',
  templateUrl: './create-department.component.html',
  styleUrls: ['./create-department.component.css']
})
export class CreateDepartmentComponent implements OnInit {
  newDepartment?: string;
  departments: Array<Department> = [];
  displayedColumns: string[] = ['number', 'department_name', 'actions'];
  saveAction: 'save' | 'update' = 'save';

  constructor(private departmentsService: DepartmentsService) { }

  ngOnInit(): void {
    this.loadDepartments();
  }

  loadDepartments(): void {
    this.departments = [];
    this.departmentsService.getAllDepartment().subscribe(
      (response: any) => {
        this.departments = response.data;
      },
      error => {
        console.error('Error loading departments:', error);
      }
    );
  }

  createDepartment(): void {
    let data: Department = {
      department_name: this.newDepartment as string,
    };
    this.departmentsService.createDepartment(data).subscribe({
      next: () => {
        Swal.fire('Success', 'Department created successfully', 'success');
        this.loadDepartments();
        this.newDepartment = '';  
      },
      error: err => {
        console.error(err);
        Swal.fire('Error', 'Failed to create department', 'error');
      },
    });
  }

  editDepartment(id: number, department: Department): void {
    let userInput = prompt(`You are updating ${department.department_name}. Enter new value in the text box below`);
    if (userInput !== null) {
      department.department_name = userInput;
      this.updateDepartment(id, department);
      console.log("User entered:", userInput);
    } else {
      console.log("Prompt canceled");
    }
  }

  updateDepartment(id: number, department: Partial<Department>): void {
    this.newDepartment = department.department_name;
    if (id !== undefined) {
      this.departmentsService.updateDepartment(id, { department_name: this.newDepartment } as Department).subscribe(
        () => {
          Swal.fire('Success', 'Department updated successfully', 'success');
          this.loadDepartments();
          this.newDepartment = '';  
        },
        error => {
          console.error('Error updating department:', error);
          Swal.fire('Error', 'Failed to update department', 'error');
        }
      );
    } else {
      console.error('ID is undefined');
      Swal.fire('Error', 'ID is undefined', 'error');
    }
  }

  deleteDepartment(id: number): void {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        if (id !== undefined) {
          this.departmentsService.remove(id).subscribe(
            () => {
              Swal.fire('Deleted!', 'Department has been deleted.', 'success');
              this.loadDepartments();
            },
            error => {
              console.error('Error deleting department:', error);
              Swal.fire('Error', 'Failed to delete department', 'error');
            }
          );
        } else {
          console.error('ID is undefined');
          Swal.fire('Error', 'ID is undefined', 'error');
        }
      }
    });
  }
}
