<h2>
    New Category</h2>
<mat-form-field appearance="outline" style="width:100%;">
    <input matInput [(ngModel)]="newCat.category_name" placeholder="category name">
</mat-form-field>

<div class="row m-3">
    <div class="col-md">
        <!-- left -->
    </div>
    <div class="col-md-auto">
        <div class="row">
            <div class="example-button-container col-md">
                <button mat-button (click)="closeDialog()">Cancel</button>
            </div>
            <div class="example-button-container col-md">
                <button mat-button (click)="createNewCategory()" color="primary">Create</button>
            </div>
        </div>


    </div>
</div>