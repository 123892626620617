import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { tap } from 'rxjs';

import { CategoryService } from 'src/app/core/api/v1/api/category.service';
import { ServicesService } from 'src/app/core/api/v1/api/services.service';
import { SubCategoryService } from 'src/app/core/api/v1/api/subCategory.service';
import { CreateServicesComponent } from './../create-services/create-services.component';
import { ServiceDocumentsComponent } from '../service-documents/service-documents.component';
import { HospitalServiceService } from '../../services/hospital-service.service';
import { HospitalServices } from '../../core/api/v1';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-list-services',
  templateUrl: './list-services.component.html',
  styleUrls: ['./list-services.component.css'],
})
export class ListServicesComponent implements OnInit {
  constructor(
    private category_service: CategoryService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private sub_category_service: SubCategoryService,
    private service_service: ServicesService
  ) {}

  displayedColumns: string[] = [
    'service_name',
    'service_key',
    'category',
    'sub_category',
    'action',
  ];

  loader: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  ngAfterViewInit() {
    this.paginator.page
      .pipe(tap(() => this.fetchServicesBySubCategoriesPage()))
      .subscribe();
  }

  ngOnInit(): void {
    this.getCategories();
    this.fetchServicesBySubCategories();
  }

  services: HospitalServices[] = [];
  categories: any = [];
  selected_category!: number;
  selected_sub_category_id!: number;

  sub_category_details: any;
  searchquery: string = '';
  dataCount: number = 0;

  getCategories() {
    this.loader = true;

    this.category_service.getAllCategories().subscribe((data: any) => {
      console.log(data, 'cats components');
      if (data.error) {
        this._snackBar.open('error', 'close');
      } else {
        this.categories = data.data;
        this.loader = false;
        this.selected_category = data.data[0].id;
        this.fetchSubCategory();
      }
    });
  }

  addDocuments(service: any) {
    console.log();
    this.dialog.open(ServiceDocumentsComponent, {
      data: {
        service: service,
      },
    });
  }

  fetchSubCategory() {
    console.log(this.selected_category);
    this.sub_category_service
      .getAllSubCategories(this.selected_category)
      .subscribe((data: any) => {
        console.log(data, 'sub category details');
        this.sub_category_details = data.data;
        this.selected_sub_category_id = data.data[0].id;
        // this.fetchServicesBySubCategories();
      });
  }

  fetchServicesBySubCategories() {
    console.log(this.searchquery, 'search query');
    this.loader = true;
    this.service_service
      .getServicesAndCount(
        10,
        0,
        'DESC',
        this.selected_sub_category_id,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      )
      .subscribe((data: any) => {
        console.log(data, 'first services');
        this.services = new MatTableDataSource<any>(data.result.list) as any;
        this.dataCount = data.result.count;
        this.loader = false;
      });
  }

  searchServices() {
    this.loader = true;
    this.selected_category = 0;
    this.selected_sub_category_id = 0;
    this.service_service
      .getServicesAndCount(
        10,
        0,
        'DESC',
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        this.searchquery
      )
      .subscribe((data: any) => {
        console.log(data, 'search services');
        this.services = new MatTableDataSource<any>(data.result.list) as any;
        this.dataCount = data.result.count;
        this.loader = false;
      });
  }

  fetchServicesBySubCategoriesPage() {
    this.service_service
      .getServicesAndCount(
        10,
        this.paginator.pageIndex,
        'DESC',
        this.selected_sub_category_id,
        undefined,
        undefined,
        undefined,
        undefined
      )
      .subscribe((data: any) => {
        console.log(data, 'data');
        this.services = new MatTableDataSource<any>(data.result.list) as any;
        this.dataCount = data.result.count;
        this.loader = false;
      });
  }

  deleteService(id: number) {
    if (confirm('Are you sure to delete ' + name)) {
      console.log('delete?');
      this.service_service.removeService(id).subscribe((data) => {
        this.fetchServicesBySubCategories();
      });
    }
  }

  // editFields(service: any) {
  //   this.dialog.open(CreateServicesComponent, {
  //     width: '1500px',
  //     height:'800px',
  //     data: { sub_cat: this.sub_category, service: service, sub_category_key:this.sub_category_details.data.sub_category_key },

  //   });
  // }

  editService(serv: any) {
    console.log(serv, 'servvvv');
    let sub_cat = serv.sub_category;
    let newService: any = Object.assign({}, serv);
    newService.sub_category = serv.sub_category?.id;
    newService.category_id = this.selected_category;
    console.log(sub_cat, 'id');
    console.log(newService, 'name');

    this.dialog
      .open(CreateServicesComponent, {
        autoFocus: false,
        data: {
          service: newService,
        },
      })
      .afterClosed()
      .subscribe({
        next: () => {
          this.services = [];
          if (this.searchquery) {
            this.searchServices();
          } else {
            this.fetchServicesBySubCategoriesPage();
          }
        },
      });
  }
}
