<h2 mat-dialog-title></h2>
<mat-dialog-content class="mat-typography">
  <div class="m-3">
    <h1>{{ package_Details?.package_name }}</h1>
    <mat-progress-bar mode="query" *ngIf="loading"></mat-progress-bar>

    <hr>
    <h3>Total Cost - NGN {{accumulatedCostOfServicesInPackage}}</h3>
    <div class="row mt-3">
      <div class="col-md-3">
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>Test Name</mat-label>
          <input matInput [(ngModel)]="test_name" />
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>Differential Key</mat-label>
          <input matInput [(ngModel)]="differential_key" />
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>Package Cost</mat-label>
          <input matInput [(ngModel)]="cost" />
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>Rank</mat-label>
          <mat-select [(ngModel)]="package_is_ranked" name="package_is_ranked">
            <mat-option *ngFor="let item of packageRankData" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <hr>

      <!--  The indexes of these tabs are used for decision making in the TS. Guides to either add optional or required services in a package -->

      <mat-tab-group dynamicHeight (selectedTabChange)="tabClick($event)">
        <mat-tab label="Mandatory Services">
          <div class="example-small-box mat-elevation-z4 mt-4">
            <div class="row">
              <mat-form-field class=" col-md-6" appearance="outline">
                <mat-label>Services</mat-label>
                <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto" />
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                  <mat-option *ngFor="let option of filteredOptions" [value]="option">
                    {{ option.service_name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <div class="col-md-6">
                <mat-form-field appearance="outline" floatLabel="always" class="col-md">
                  <mat-label>Service Amount(NGN)</mat-label>
                  <input matInput type="number" [(ngModel)]="serviceToPackageCost" placeholder="0">
                </mat-form-field>
              </div>

              <div class="row mr-3 mb-3">
                <div class="col"></div>
                <div class="col-md-auto">
                  <button mat-flat-button color="primary" (click)="addService()">
                    Add Service
                  </button>
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-md">
                <button class="col-md-1" type="button" class="btn btn-warning"
                  *ngFor="let service of selectedServices; let i = index" style="margin: 2px">
                  {{ service?.service_name }} - ( NGN {{service?.service_cost}} )
                  <span (click)="removeService(service, i)" class="badge badge-light">X</span>
                </button>
              </div>
            </div>

          </div>
        </mat-tab>
        <mat-tab label="Optional Services">
          <div class="example-small-box mat-elevation-z4 mt-4">
            <div class="row">
              <mat-form-field class=" col-md-6" appearance="outline">
                <mat-label>Services</mat-label>
                <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto" />
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                  <mat-option *ngFor="let option of filteredOptions" [value]="option">
                    {{ option.service_name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <div class="col-md-6">
                <mat-form-field appearance="outline" floatLabel="always" class="col-md">
                  <mat-label>Service Amount(NGN)</mat-label>
                  <input matInput type="number" [(ngModel)]="serviceToPackageCost" placeholder="0">
                </mat-form-field>
              </div>

              <div class="row mr-3 mb-3">
                <div class="col"></div>
                <div class="col-md-auto">
                  <button mat-flat-button color="primary" (click)="addOptionalService()">
                    Add Service
                  </button>
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-md">
                <button class="col-md-1" type="button" class="btn btn-warning"
                  *ngFor="let service of selected_optional_Services; let i = index" style="margin: 2px">
                  {{ service?.service_name }} - ( NGN {{service?.service_cost}} )
                  <span (click)="removeOptionalService( i)" class="badge badge-light">X</span>
                </button>
              </div>
            </div>

          </div>
        </mat-tab>
      </mat-tab-group>





    </div>
    <hr />

    <div class="row m-3">
      <div class="col-md">
      </div>
      <div class="col-md-auto">
        <div class="row">
          <div class="example-button-container col-md">
            <button mat-button (click)="deleteTests()">Delete</button>
          </div>
          <div class="example-button-container col-md">
            <button mat-button color="primary" (click)="processTestPackages()">
              Create
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>