/**
 * services-service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Templates { 
    id: number;
    templates_name?: string;
    url?: string;
    key?: string;
    imageData?: string;
    service_id?: number;
    service_ids?: Array<number>;
    has_multiple_services?: boolean;
    created: string;
    updated: string;
    deletedAt?: string;
}

