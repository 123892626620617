export * from './category.service';
import { CategoryService } from './category.service';
export * from './departments.service';
import { DepartmentsService } from './departments.service';
export * from './packageServices.service';
import { PackageServicesService } from './packageServices.service';
export * from './radiologyServices.service';
import { RadiologyServicesService } from './radiologyServices.service';
export * from './resultTemplate.service';
import { ResultTemplateService } from './resultTemplate.service';
export * from './services.service';
import { ServicesService } from './services.service';
export * from './servicesExtraDataInAParticularPackage.service';
import { ServicesExtraDataInAParticularPackageService } from './servicesExtraDataInAParticularPackage.service';
export * from './subCategory.service';
import { SubCategoryService } from './subCategory.service';
export * from './uploads.service';
import { UploadsService } from './uploads.service';
export const APIS = [CategoryService, DepartmentsService, PackageServicesService, RadiologyServicesService, ResultTemplateService, ServicesService, ServicesExtraDataInAParticularPackageService, SubCategoryService, UploadsService];
